import { useState } from 'react';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { CaptureFinger } from '../utils/biometric';
import { Spin, notification } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import { BACKEND_URL } from '../constants';

const EditFingerprint = ({ rightThumb, setRightThumb, registeredThumb }) => {
  const [thumbLoading, setThumbLoading] = useState(false);

  var quality = 60; //(1 to 100) (recommanded minimum 55)
  var timeout = 10;

  const captureFinger = async () => {
    await setThumbLoading(true);

    try {
      var res = await CaptureFinger(quality, timeout);
      if (res.httpStaus) {
        if (res.data.ErrorCode == '0') {
          if (res.data.Quality >= 60) {
            setRightThumb({
              iso: res.data.IsoTemplate,
              bitmap: res.data.BitmapData
            });
            notification.success({
              message: 'Fingerprint captured!!'
            });
          } else {
            notification.warning({
              message: 'Finger not detected properly!!'
            });
          }
        } else if (res.data.ErrorCode == '-1140') {
          notification.warning({
            message: 'No finger detected!!'
          });
        }
      } else {
        alert(res.err);
      }
      setThumbLoading(false);
    } catch (e) {
      alert(e);
      setThumbLoading(false);
    }
    return false;
  };

  //delete finger print api incase we need it in future
  const deleteFingerPrint = () => {
    fetch(`${BACKEND_URL}/deleteFingerprint/${registeredThumb?.id}`, {
      method: 'DELETE'
    })
      .then(async (res) => {
        const data = await res.json();
        notification.success({
          message: data?.message
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className=" w-[28rem]   items-start">
      {console.log(rightThumb, 'thumb')}
      <div className="w-[7rem] flex flex-col  justify-start items-center  ">
        <div className="flex  flex-col w-[7rem] h-[9rem] border-2 items-center">
          {registeredThumb?.bitmap || rightThumb?.bitmap ? (
            <img
              id="imgFinger"
              src={`data:image/bmp;base64,${registeredThumb?.bitmap ? registeredThumb?.bitmap : rightThumb?.bitmap}`}
              className={`w-[7rem] h-[9rem] object-fill `}
            />
          ) : null}
        </div>

        <div className="border-2 mt-2 w-[7rem] rounded ">
          {registeredThumb?.bitmap ? (
            <button
              onClick={() => {
                // deleteFingerPrint();
                // return;
                notification.warning({
                  message: 'You are not authorised to delete!'
                });
              }}
              type="button"
              className="px-2  h-7  rounded my-1 w-[7rem]"
            >
              <div className="text-red-400">
                <DeleteIcon />
                Delete
              </div>
            </button>
          ) : (
            <button onClick={() => captureFinger()} type="button" className="px-2 h-7 rounded my-1 w-[7rem]">
              {thumbLoading ? <HourglassTopIcon /> : 'Add'}
            </button>
          )}
        </div>
      </div>
      {rightThumb?.bitmap ? 'Fingerprint captured successfully!' : null}
    </div>
  );
};

export default EditFingerprint;
