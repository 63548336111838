import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from 'pages/Home';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Transmissions from 'pages/Transmissions';
import NewEntry from 'pages/NewEntry';
import PeopleManagement from 'pages/People';
import Villages from 'pages/Villages';
import VillageContext from 'context/VillageContext';
import { useEffect, useState } from 'react';
import LoginPage from 'pages/Login';
import EditResidentDetails from 'pages/EditResidentDetails';
import { NODE_CODE, NODE_NAME, VILLAGE_CODE, VILLAGE_NAME } from 'constants';
import { ReactComponent as HomeIcon } from 'images/HomeIcon.svg';
import { ReactComponent as ResidentsIcon } from 'images/ResidentsIcon.svg';
import { ReactComponent as EntryExitIcon } from 'images/EntryExitIcon.svg';
import { useMatch } from 'react-router-dom';
import { useContext } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [village, setVillage] = useState('');
  const [node, setNode] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(true);
  const { theme, toggleTheme } = useContext(ThemeContext);
  useEffect(() => {
    const vil = localStorage.getItem('village');
    const nod = localStorage.getItem('node');

    const auth = localStorage.getItem('authenticated');
    const type = localStorage.getItem('userType');

    setAuthenticated(auth);
    setUserType(type);

    if (vil && nod) {
      setVillage(vil);
      setNode(nod);
    } else {
      setVillage(`${VILLAGE_CODE}-${VILLAGE_NAME}`);
      setNode(`${NODE_CODE}-${NODE_NAME}`);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div className="h-[100vh] w-[100vw] flex justify-center items-center">Loading...</div>;
  }

  function CustomToolbar({ to, label, Icon }) {
    const match = useMatch(to);

    return (
      <Link to={to}>
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div
            className={`h-150 w-150 ${match ? 'bg-green' : ''}`}
            style={{
              display: 'inline-block',
              padding: match ? '10px' : '0px',
              borderRadius: '100%'
            }}
          >
            <div>
              {!match && (
                <Icon
                  style={{
                    height: '24px',
                    objectFit: 'contain',
                    fill: theme.palette.mode === 'dark' ? 'white' : '#191818'
                  }}
                />
              )}
              {match && (
                <Icon
                  style={{ height: '24px', objectFit: 'contain', fill: theme.palette.mode === 'dark' && 'white' }}
                />
              )}
            </div>
          </div>
          <div className={match ? 'text-green-dark dark:text-green-400' : ''} style={{ fontSize: '12px' }}>
            {label}
          </div>
        </Toolbar>
      </Link>
    );
  }
  return (
    <VillageContext.Provider
      value={{
        village,
        setVillage,
        node,
        setNode,
        authenticated,
        setAuthenticated,
        userType,
        setUserType
      }}
    >
      <ToastContainer />
      <div className={theme.palette.mode == 'light' ? 'App' : 'App dark'}>
        {authenticated ? (
          <BrowserRouter>
            <Header />
            <div className="flex relative app-container space-x-4" style={{ height: '100vh' }}>
              <div
                style={{
                  height: '100vh',
                  flexDirection: 'column',
                  alignContent: 'center',
                  paddingLeft: '24px',
                  paddingRight: '24px'
                }}
              >
                <div
                  style={{
                    border: '1px solid #4E4E4D',
                    borderRadius: '60px',
                    background: theme.palette.mode === 'dark' ? '#191818' : 'white',
                    paddingTop: '24px',
                    paddingBottom: '28px'
                  }}
                >
                  <div style={{ marginBottom: '18px' }}>
                    <CustomToolbar to="/" label="Home" Icon={HomeIcon} />
                  </div>
                  <div style={{ marginBottom: '18px' }}>
                    <CustomToolbar to="/transmissions" label="Entry/Exit" Icon={EntryExitIcon} />
                  </div>
                  <CustomToolbar to="/people" label="Residents" Icon={ResidentsIcon} />
                </div>
              </div>
              <div className="pages">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/transmissions" element={<Transmissions />} />
                  <Route path="/new" element={<NewEntry />} />
                  <Route path="/people" element={<PeopleManagement />} />
                  <Route path="/villages" element={<Villages />} />
                  <Route path="/edit-resident-details" element={<EditResidentDetails />} />
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<LoginPage />} />
            </Routes>
          </BrowserRouter>
        )}
      </div>
    </VillageContext.Provider>
  );
}

export default App;
