export const VILLAGE_NAME = '62 RR';
export const VILLAGE_CODE = 'V1000';

export const NODE_NAME = 'NODE 10';
export const NODE_CODE = 'N0010';

export const FINGERPRINT_URL_SECURE = 'https://localhost:8003/mfs100/';
export const FINGERPRINT_URL_INSECURE = 'http://localhost:8004/mfs100/';

// export const RTSP_URL = process.env.REACT_APP_RTSP_URL; //camera ip
export const RTSP_URL = 'rtsp://192.168.1.41/hd1'; //camera ip

export const STREAM_URL = 'ws://localhost'; //backend ip
// export const STREAM_URL = "ws://localhost";

export const currentFr = 'ayonix';
// export const currentFr = "futuretech";

export const FRS_URL = 'http://192.168.1.23:8080/face';

// export const FRS_URL =
//   currentFr == "ayonix"
//     ? "http://localhost:8080/face"
//     : "http://localhost:8000"; //ip

// export const FRS_URL =
//   currentFr == "ayonix"
//     ? "http://localhost:8080/face"
//     : "http://localhost:8000"; //localhost

function defaultvalue(nullable, val) {
  if (nullable) return nullable;
  else return val;
}

// export const BACKEND_URL = defaultvalue(process.env.REACT_APP_BACKEND_URL, 'http://localhost:4101'); //ip
// export const PYTHON_BACKEND_URL = defaultvalue(process.env.REACT_APP_PYTHON_BACKEND_URL, 'http://localhost:5000'); //ip
// export const PYTHON_FRS_URL = defaultvalue(process.env.REACT_APP_PYTHON_FRS_URL, 'http://localhost:5500'); //ip

export const BACKEND_URL = defaultvalue(process.env.REACT_APP_BACKEND_URL, 'https://api.node.seeker.zendot.in'); //ip
export const PYTHON_BACKEND_URL = defaultvalue(process.env.REACT_APP_PYTHON_BACKEND_URL, 'https://flask.seeker.zendot.in'); //ip
export const PYTHON_FRS_URL = defaultvalue(process.env.REACT_APP_PYTHON_FRS_URL, 'https://flask.seeker.zendot.in'); //ip

// export const BACKEND_URL = "http://localhost:4000"; //localhost
