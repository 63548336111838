import React, { useState, useMemo, useEffect, useContext } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';

import { mkConfig, generateCsv, download } from 'export-to-csv'; //o
import { DatePicker, Select, Option, Spin, Modal, notification } from 'antd';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ViewImageModal from '../Modals/ViewImageModal';
import VillageContext from '../context/VillageContext';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { BACKEND_URL } from '../constants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ClearIcon from '@mui/icons-material/Clear';

const PeopleManagement = () => {
  const [peopleData, setPeopleData] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [villageOptions, setVillageOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const [totalRecords, setTotalRecords] = useState();
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();

  const { confirm } = Modal;

  const { village, setVillage, node, setNode, userType } = useContext(VillageContext);

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'user_id', //normal accessorKey
        header: 'Resident ID',
        size: 150
      },
      {
        accessorKey: 'imagebase64', //normal accessorKey
        header: 'Image',
        size: 50,
        accessorFn: (row) => {
          return (
            <ViewImageModal
              image={row.imagebase64}
              metaData={{
                ...row,
                userInfo: { f_name: row.f_name, l_name: row.l_name }
              }}
            />
          );
        }
      },
      {
        accessorKey: 'f_name',
        header: 'First Name',
        size: 150
      },
      {
        accessorKey: 'l_name',
        header: 'Last Name',
        size: 150
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 100
      },
      {
        accessorKey: 'village.v_name', //normal accessorKey
        header: 'Village',
        size: 200
      },
      {
        accessorKey: 'dob', //normal accessorKey
        header: 'DOB',
        size: 50,
        accessorFn: (row) => (row.dob ? moment(row.dob).format('MM-DD-YYYY') : '-')
      },
      {
        accessorKey: 'age', //normal accessorKey
        header: 'Age',
        size: 50
      },
      {
        accessorKey: 'aadhar', //normal accessorKey
        header: 'Aadhaar',
        size: 200
      },
      {
        accessorKey: 'address', //normal accessorKey
        header: 'Address',
        size: 200
      },
      {
        accessorKey: 'contact', //normal accessorKey
        header: 'Contact',
        size: 200
      },

      {
        accessorKey: 'village_id', //normal accessorKey
        header: 'Village ID',
        size: 100
      },
      {
        accessorKey: 'city', //normal accessorKey
        header: 'City',
        size: 200
      },
      {
        accessorKey: 'country', //normal accessorKey
        header: 'Country',
        size: 200
      },
      {
        accessorKey: 'wanted', //normal accessorKey
        header: 'Is Wanted?',
        size: 200
      },

      {
        accessorKey: 'action', //normal accessorKey
        header: 'Actions',
        size: 100,
        accessorFn: (row) =>
          userType == 'admin' ? (
            <div className="cursor-pointer">
              <Link to="/edit-resident-details" state={{ user: row }}>
                <ModeEditIcon />
              </Link>
              <DeleteIcon
                onClick={() => {
                  handleDeleteUser(row.user_id);
                }}
                className="text-red-500"
              />
            </div>
          ) : (
            'No Actions Permitted'
          )
      }
    ],
    []
  );

  useEffect(() => {
    getPeopleOptions();
    getVillageOptions();
  }, [village]);

  useEffect(() => {
    if (filters?.village_ids?.length > 0) {
      getPeopleData();
    }
  }, [currentPage, rowsPerPage, villageOptions, village]);

  const getPeopleData = () => {
    setLoading(true);
    const village_ids = filters?.village_ids?.map((element) => element.split('-')[0]);
    fetch(`${BACKEND_URL}/getUserInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          ...filters,
          village_ids
        },
        limit: parseInt(rowsPerPage),
        page: currentPage
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setPeopleData(data?.result);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getVillageOptions = () => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getVillageDetails`)
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((village, i) => {
          return {
            label: village.v_name,
            value: village.village_id + '-' + village.v_name
          };
        });
        setVillageOptions(options);
        setFilters({ village_ids: [village] });
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getPeopleOptions = () => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getUserNames`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((person, i) => {
          return {
            label: person.user_name,
            value: person.user_id + '-' + person.user_name
          };
        });
        setPeopleOptions(options);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(
      peopleData.map((row) => {
        const { village, ...rest } = row;
        return { rest, ...village };
      })
    );
    download(csvConfig)(csv);
  };

  const handleDeleteUser = async (user_id) => {
    notification.warning({
      message: 'You are not authorized to delete Residents Details'
    });

    return;

    // if (!user_id) return;

    // confirm({
    //   title: 'Are you sure delete this User?',
    //   icon: <ExclamationCircleFilled />,
    //   // content: '', // If need add content
    //   okText: 'Yes',
    //   okType: 'danger',
    //   cancelText: 'No',
    //   onOk() {
    //     deleteUserDetail(user_id);
    //   }
    // });
  };

  const deleteUserDetail = async (user_id) => {
    try {
      setLoading(true);
      const deleteUser = await fetch(`${BACKEND_URL}/deleteUserInfo/${user_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const response = await deleteUser.json();

      if (response.statusCode === 204) {
        notification.success({
          message: 'User deleted successfully'
        });
        getPeopleData();
      } else {
        notification.error({
          message: 'Error while deleting user'
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error while deleting user', error);
      notification.error({
        message: 'Error while deleting user'
      });
    }
  };

  const handleFileImport = async (e) => {
    const files = e.target.files;
    if (files) {
      try {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true);
        const importUser = await fetch(`${BACKEND_URL}/importUserInfo`, {
          method: 'POST',
          body: formData
        });
        const response = await importUser.json();
        if (response.statusCode === 200) {
          notification.success({
            message: response.message
          });
          setFile();
          getPeopleData();
        } else {
          notification.error({
            message: 'Error while importing file'
          });
          setLoading(false);
        }
      } catch (error) {
        console.error('Error while importing file', error);
        notification.error({
          message: 'Error while importing file'
        });
        setLoading(false);
      }
    }
  };

  const table = useMaterialReactTable({
    columns,

    initialState: {
      density: 'compact',
      columnVisibility: {
        'village.v_name': false,
        'nodeDetails.n_name': false,
        user_id: false,
        aadhar: false,
        city: false,
        country: false,
        wanted: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    enableFilters: false,
    data: peopleData,
    enablePagination: false,
    enableBottomToolbar: false,
    renderTopToolbarCustomActions: () => (
      <div className="flex items-end justify-between w-full">
        <div className="flex items-end">
          <div className="flex flex-col ml-2">
            <div className="text-left text-[1rem] text-gray-500">Select Village</div>

            <Select
              placeholder="Select Village"
              options={[
                {
                  label: 'All Villages',
                  value: 'all'
                },
                ...villageOptions
              ]}
              mode="multiple"
              allowClear
              value={filters.village_ids ? filters.village_ids : null}
              onChange={(e) => {
                if (e.includes('all')) {
                  if (filters?.village_ids?.length > 0 && filters?.village_ids?.length + 1 == e?.length) {
                    setFilters({ ...filters, village_ids: [] });
                  } else {
                    const ids = villageOptions.map((vill) => vill.value);
                    setFilters({ ...filters, village_ids: ids });
                  }
                } else {
                  const ids = e.map((village) => village);
                  setFilters({ ...filters, village_ids: ids });
                }
              }}
              showSearch
              className=" w-[15rem] text-left dark:rounded-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              maxTagCount={'responsive'}
            >
              {[
                {
                  label: 'All Villages',
                  value: 'all'
                },
                ...villageOptions
              ].map((vill, i) => (
                <Select.Option value={vill.value} key={i}>
                  {vill.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-col ml-2 table-filter">
            <div className="text-left text-[1rem] text-gray-500 ">Select People</div>
            <Select
              placeholder="Search People"
              options={peopleOptions}
              allowClear={true}
              onChange={(e) => {
                console.log(e);
                if (e) {
                  const dt = e.split('-');
                  console.log(dt, e, 'hello');

                  let id;
                  if (dt.length == 2) {
                    id = dt[0];
                  } else if (dt.length == 3) {
                    id = dt[0] + '-' + dt[1];
                  }
                  setFilters({ ...filters, user_id: id });
                } else {
                  setFilters({ ...filters, user_id: undefined });
                }
              }}
              className="w-[15rem] px-2 text-left dark:rounded-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              showSearch
            />
          </div>

          <button className=" px-3 py-1 rounded ml-3 h-[2rem] bg-green-500 text-white " onClick={getPeopleData}>
            Submit
          </button>
        </div>
      </div>
    )
  });

  const handleNextPage = () => {
    if (currentPage == Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage == 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin className="mt-[9rem]" size="large" spinning={loading}>
      <div className="w-[97%] h-auto table-container mx-[auto] overflow-auto ">
        <div className="py-4 my-3 text-3xl font-bold text-left text-blue-500 border-b-2 ">Residents</div>
        <MaterialReactTable table={table} />
        <div className="flex justify-between mt-3 mb-5">
          <div>
            <span>Rows Per Page:</span>
            <Select
              className="mx-2"
              options={[
                {
                  value: '5',
                  label: '5'
                },
                {
                  value: '10',
                  label: '10'
                },
                {
                  value: '20',
                  label: '20'
                }
              ]}
              onChange={(e) => {
                setRowsPerPage(e);
                setCurrentPage(1);
              }}
              value={rowsPerPage}
            />
          </div>
          <div className="flex items-center ">
            <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
            <div>
              {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
            </div>
            <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default PeopleManagement;
