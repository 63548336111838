import { useEffect, useState } from 'react';
import { Modal, Button as AntDButton } from 'antd';
import { Button } from '@mui/material';
import { BACKEND_URL, RTSP_URL, STREAM_URL, PYTHON_BACKEND_URL } from '../constants';
// import { socket } from "../services";
import io from 'socket.io-client';
import CachedIcon from '@mui/icons-material/Cached';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { ReactComponent as InfoIcon } from '../images/info-icon.svg';
import LoadingIcon from '../images/LoadingIcon';
const LOCAL_RTSP_URL_KEY = 'LAST_SAVED_RTSP_URL';
const LOCAL_STREAM_STARTED_KEY = 'STREAM_STARTED';

const Rtsp = ({ capture, captureFace, captureDoc, canvasClassName, backToWebCam, showCaptureButton = false }) => {
  // const [showModal, setShowModal] = useState(true);

  // const [streamURL, setStreamURL] = useState(RTSP_URL);
  const [startingStream, setStartingStream] = useState(false);

  const [stream, setStream] = useState('');
  const [faceImage, setFaceImage] = useState(null);
  const [docImage, setDocImage] = useState(null);
  // const [streamStarted, setStreamStarted] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [streamURL, setStreamURL] = useState(() => {
    const savedStreamURL = Cookies.get(LOCAL_RTSP_URL_KEY);
    // const savedStreamURL = localStorage.getItem(LOCAL_RTSP_URL_KEY);
    if (savedStreamURL !== null && savedStreamURL !== 'undefined') {
      return savedStreamURL === '0' ? 0 : savedStreamURL;
    }
    return RTSP_URL;
  });

  // Update localStorage whenever streamURL changes
  useEffect(() => {
    Cookies.set(LOCAL_RTSP_URL_KEY, streamURL, { expires: 365 });
    console.log(LOCAL_RTSP_URL_KEY);
    console.log(streamURL);
    // localStorage.setItem(LOCAL_RTSP_URL_KEY, streamURL);
  }, [streamURL]);

  // Retrieve initial value for streamStarted from localStorage
  const [streamStarted, setStreamStarted] = useState(() => {
    const savedStreamStarted = Cookies.get(LOCAL_STREAM_STARTED_KEY);
    return savedStreamStarted === 'true';
  });

  useEffect(() => {
    Cookies.set(LOCAL_STREAM_STARTED_KEY, streamStarted);
  }, [streamStarted]);

  useEffect(() => {
    const socket = io(PYTHON_BACKEND_URL);

    socket.on('stream', (frameEncoded) => {
      // console.log("stream received "+(new Date()).toISOString());
      setStream('data:image/jpg;base64,' + frameEncoded);
      const img = 'data:image/jpg;base64,' + frameEncoded;
      setDocImage(img);
    });

    socket.on('face_stream', (frameEncoded) => {
      const face = 'data:image/jpg;base64,' + frameEncoded;
      setFaceImage(face);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    // startStream(); // Start stream when component mounts
    // return () => {
    //   stopStream(); // Stop stream when component unmounts
    // };
  }, []);

  const restartStream = () => {};

  // Event handler for starting the stream
  const startStream = async () => {
    setStartingStream(true);
    try {
      const response = await fetch(PYTHON_BACKEND_URL + '/startStream', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ rtsp_url: streamURL }) // Replace with your RTSP URL
      });
      if (response.ok) {
        console.log('stream started 22222');
        setStreamStarted(true);
      } else {
        // alert("Failed to start stream");
        toast.error('Failed to start stream');

        if (backToWebCam) {
          backToWebCam();
        }
        console.error('Failed to start stream');
      }
    } catch (error) {
      if (backToWebCam) {
        backToWebCam();
      }
      console.error('Error starting stream:', error);
    } finally {
      setStartingStream(false);
    }
  };

  // Event handler for stopping the stream
  const stopStream = async () => {
    try {
      const response = await fetch(PYTHON_BACKEND_URL + '/stopStream', {
        method: 'POST'
      });
      if (response.ok) {
        setStreamStarted(false);
        setStream('');
      } else {
        console.error('Failed to stop stream');
      }
    } catch (error) {
      console.error('Error stopping stream:', error);
    }
  };

  const captureImage = () => {
    capture(docImage, faceImage);
  };

  const captureFaceHandler = () => {
    captureFace(true, faceImage);
  };

  const captureDocHandler = () => {
    captureDoc(true, docImage);
  };

  const handleInfoClick = () => {
    setShowInfo((prevState) => !prevState);
  };
  const handleModalClose = () => {
    setShowInfo(false);
  };

  return (
    <div className="w-full  items-center relative">
      <div>
        <div className="flex justify-start text-lg font-bold ">
          Enter Stream URL
          <div className="flex  items-center justify-center px-1">
            <InfoIcon className="h-4 w-4 cursor-pointer" onClick={handleInfoClick} />
            <Modal
              title="More Information"
              visible={showInfo}
              onCancel={handleModalClose}
              footer={[
                <AntDButton key="ok" type="primary" onClick={handleModalClose}>
                  OK
                </AntDButton>
              ]}
            >
              <div className="">
                <div className="flex justify-start py-1">Body cam url example: rtsp://your_body_cam_ip/hd1</div>
                <div className="flex justify-start py-1">
                  your_body_cam_ip : This can be found in body cam settings, named as "WIFI Ip Address"
                </div>
                <div className="flex justify-start py-1">External Web Cam example: /dev/video0</div>
                <div className="flex justify-start py-1">All options here:</div>
                <div className="flex justify-start ">option 1 : /dev/video0</div>
                <div className="flex justify-start ">option 2 : /dev/video1</div>
                <div className="flex justify-start ">option 3 : /dev/video2</div>
                <div className="flex justify-start ">option 4 : /dev/video3</div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="flex">
          <input
            value={streamURL}
            onChange={(e) => {
              if (e.target.value === '0') setStreamURL(0);
              else setStreamURL(e.target.value);
            }}
            // placeholder="rtsp://192.168.29.143:8080/h264_ulaw.sdp"
            placeholder="Enter URL"
            className="border-2 w-5/6 h-[2rem] rounded focus:outline-none px-2 my-2"
          />
          {/* start stop stream buttons */}
          <div className="flex w-1/6 h-[2rem] rounded px-2 my-2 justify-start">
            {!streamStarted && (
              <button
                type="button"
                onClick={() => {
                  if (streamURL === undefined || streamURL === '' || streamURL === 'undefined') {
                    toast.error('Enter a URL');
                  } else {
                    startStream();
                  }
                }}
                className="bg-blue-600 text-white px-1 py-1 rounded text-md flex justify-end"
              >
                {startingStream && <LoadingIcon />}
                Start Stream
              </button>
            )}

            {streamStarted && (
              <button
                type="button"
                onClick={() => stopStream()}
                className="bg-blue-600 text-white px-1 py-1 rounded text-md"
              >
                Stop Stream
              </button>
            )}
          </div>
        </div>

        {/* </div> */}
      </div>
      {stream && <img src={stream} alt="Video Stream" />}

      {streamStarted && (
        <>
          {showCaptureButton && (
            <div className="flex mt-5 gap-4 justify-between">
              <Button
                onClick={captureFaceHandler}
                type="button"
                variant="contained"
                className=" text-white mt-[1rem] rounded "
                style={{ backgroundColor: '#22c55e', padding: '1em' }}
                startIcon={
                  <PersonIcon
                    fontSize="large"
                    style={{
                      fontSize: '40px'
                    }}
                  />
                }
              >
                <span>
                  Capture
                  <p className="font-bold">Person</p>
                </span>
              </Button>
              <Button
                onClick={captureDocHandler}
                type="button"
                variant="contained"
                className=" text-white mt-[1rem] rounded "
                style={{ backgroundColor: '#22c55e', padding: '1em', height: 'fit-content' }}
                startIcon={<DocumentScannerIcon style={{ fontSize: '40px' }} />}
              >
                <span>
                  Capture
                  <p className="font-bold">Document</p>
                </span>
              </Button>
            </div>
          )}
          {!showCaptureButton && (
            <button
              onClick={captureImage}
              type="button"
              className="mt-[1rem]  px-[2rem] py-[.5rem] text-xl self-end bg-blue-500 text-white rounded "
            >
              Capture & Register
            </button>
          )}
          {/* <button
        disabled={startingStream}
        type="button"
        onClick={startStream}
        className={
          `mt-[1rem] mx-2  px-[2rem] py-[.5rem] text-xl self-end bg-green-500 text-white rounded ` +
          (startingStream
            ? "bg-gray-400 cursor-not-allowed"
            : "hover:bg-green-700")
        }
      >
        <CachedIcon />
      </button> */}
        </>
      )}
    </div>
  );
};

export default Rtsp;
