import EditIcon from '@mui/icons-material/Edit';
import { Modal, Select } from 'antd';
import { useEffect, useState, useContext } from 'react';
import VillageContext from '../context/VillageContext';
import { BACKEND_URL } from '../constants';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const ChangeVillage = () => {
  const [show, setShow] = useState(false);

  const [villages, setVillages] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState('');
  const [nodes, setNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { village, setVillage, node, setNode } = useContext(VillageContext);

  useEffect(() => {
    // window.location.reload(true);
    getVillages();
  }, []);

  useEffect(() => {
    setSelectedNode('');
    if (!!selectedVillage) getNodes();
  }, [selectedVillage]);

  useEffect(() => {
    setSelectedVillage(village.split(['-'])[1]);
    setSelectedNode(node.split(['-'])[1]);
  }, []);

  useEffect(() => {
    const storedVillage = Cookies.get('village');
    const storedNode = Cookies.get('node');

    if (storedVillage) {
      console.log(storedVillage);
      setSelectedVillage(storedVillage);
      setVillage(storedVillage);
    }
    if (storedNode) {
      console.log(storedNode);
      setSelectedNode(storedNode);
      setNode(storedNode);
    }
  }, []);

  const getVillages = () => {
    fetch(`${BACKEND_URL}/getVillageDetails`)
      .then((res) => res.json())
      .then((data) => {
        const options = data.result?.map((vill) => {
          return {
            label: vill.v_name,
            value: vill.village_id + '-' + vill.v_name
          };
        });
        setVillages(options);
      })
      .catch((err) => console.log(err));
  };

  const getNodes = () => {
    fetch(`${BACKEND_URL}/getNode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          village_id: selectedVillage?.split('-')[0]
        }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result?.map((node) => {
          return {
            label: node.n_name,
            value: node.node_id + '-' + node.n_name
          };
        });
        console.log(options);
        setNodes(options);
      });
  };

  const handleSubmit = () => {
    if (!selectedVillage || !selectedNode) {
      setErrorMessage('Please select both a village and a node.');
      toast.error('Please select both village and a node');
      return;
    }
    // localStorage.setItem("village", selectedVillage);
    // localStorage.setItem("node", selectedNode);
    Cookies.set('village', selectedVillage, { expires: 365 });
    Cookies.set('node', selectedNode, { expires: 365 });
    setVillage(selectedVillage);
    setNode(selectedNode);
    setShow(false);
    setErrorMessage(''); // Clear error message on successful submit
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Modal open={show} footer={null} closeIcon={null}>
        Change Village and node
        <div className="flex mb-2">
          <div className="w-full">
            <div>Select Village</div>
            <Select
              className="w-full"
              options={villages}
              onChange={(e) => {
                // const value = e.split("-")[0];
                setSelectedVillage(e);
                setSelectedNode(null);
              }}
              placeholder="Mandi"
            />
          </div>
          <div className="w-full">
            <div>Select Node</div>
            <Select
              className="w-full ml-2"
              options={nodes}
              value={selectedNode}
              onChange={(e) => {
                // const value = e.split("-")[0];
                setSelectedNode(e);
              }}
              placeholder="North Entrance"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button onClick={() => setShow(false)} className="border border-gray-200 px-2 py-1 rounded">
            Cancel
          </button>
          <button onClick={() => handleSubmit()} className="bg-blue-600 px-2 py-1 rounded text-white ml-2">
            Submit
          </button>
        </div>
      </Modal>
      <button className="btn btn-green" onClick={() => setShow(true)}>
        <EditIcon />
      </button>
    </div>
  );
};

export default ChangeVillage;
