import logo from '../images/Nure_FutureTech-icon.png';
import PlaceIcon from '@mui/icons-material/Place';
import ChangeVillage from '../Modals/ChangeVillage';
import { useContext, useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import VillageContext from '../context/VillageContext';
import ArmyLogo1 from '../images/army-logo-1.png';
import ArmyLogo2 from '../images/army-logo-2.png';
import { ReactComponent as LogoutLogo } from '../images/logout-logo.svg';
import ArmyLogo3 from '../images/army-logo-3.png';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ThemeContext } from '../index';
import MaterialUISwitch from '../utils/designs/MaterialUISwitch';
import { BACKEND_URL, VILLAGE_NAME } from '../constants';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import LoadingIcon from '../images/LoadingIcon';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import html2canvas from 'html2canvas';

import { ReactComponent as InfoIcon } from '../images/info-icon.svg';
const Header = () => {
  const { village, node, setAuthenticated, userType, setUserType } = useContext(VillageContext);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState("5");
  const [file, setFile] = useState();
  const [transactionData, setTransactionData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [lastImportTime, setLastImportTime] = useState(null);
  const handleInfoClick = () => {
    setShowInfo((prevState) => !prevState);
  };
  const handleModalClose = () => {
    setShowInfo(false);
  };
  useEffect(() => {
    const savedLastImportTime = Cookies.get('lastImportTime');
    if (savedLastImportTime) {
      setLastImportTime(new Date(savedLastImportTime));
    }
  }, []);
  const fromDate = useState(
    moment.tz(dayjs(), 'Asia/Kolkata').add(-1, 'days').add(-18.5, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );
  const endDate = useState(
    moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(1, 'day')
      .add(5.5, 'hours')
      .add(-1, 'minutes')
      .add(59, 'seconds')
      .add(999, 'milliseconds')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );

  const handleFileImportHeader = (event) => {
    // e.stopPropagation();
    console.log('header');
    setLoading(true);
    const files = event.target.files;
    if (files) {
      setFile(event.target.files[0]);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      fetch(`${BACKEND_URL}/importall`, {
        method: 'POST',
        body: formData
      })
        .then(() => {
          // getTransactionsHeader();
          // openNotification(file);
          toast.success('File Imported Successfully');
          const currentTime = new Date();
          setLastImportTime(currentTime);
          Cookies.set('lastImportTime', currentTime.toISOString(), {
            expires: 365
          });
          setLoading(false);
          setFile();
          event = null;
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error('Error in file uploading');
        });
    }
  };

  const getTransactionsHeader = () => {
    // e.stopPropagation();
    setLoading(true);
    fetch(`${BACKEND_URL}/getTransaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: endDate,
        limit: parseInt('5'),
        page: 1,
        q: {
          village_id: [village.split('-')[0]]
        }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setTransactionData(data?.result);
        setTotalRecords(data?.totalRecords);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const handleExport = () => {
    setExportLoading(true);
    fetch(`${BACKEND_URL}/exportall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'blob'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(async (encryptedBlob) => {
        // const data = await res.json();
        // const encryptedData = data.encryptedData;

        // const encryptedBlob = new Blob([encryptedData], { type: "application/octet-stream" });

        const url = window.URL.createObjectURL(encryptedBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = moment().format('YYYYMMDD') + VILLAGE_NAME + '-Node.enc';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      })
      .then(() => setExportLoading(false))
      .catch((err) => {
        toast.error('Error in exporting file');
        console.log(err);
      });
  };

  const handlePrint = () => {
    html2canvas(document.body).then((canvas) => {
      const dataUrl = canvas.toDataURL();
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`<img src="${dataUrl}" style="width:100%"/>`);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        printWindow.close(); // Close the window after printing
      };
    });
  };
  return (
    <>
      <div
        className="flex justify-between"
        style={{
          paddingRight: '24px',
          paddingLeft: '24px',
          paddingTop: '22px',
          // paddingBottom: "22px",
          background: theme.palette.mode === 'dark' ? '#191818' : 'white'
        }}
      >
        <div className="flex justify-between items-center" style={{ borderRadius: '100px' }}>
          <div
            className="flex overflow-hidden"
            style={{
              backgroundColor: '#4E4E4D',
              borderRadius: '100px',
              paddingLeft: '10px',
              paddingRight: '0px'
            }}
          >
            <div
              className="flex overflow-hidden"
              style={{
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '8px',
                paddingBottom: '8px'
              }}
            >
              <img
                src={ArmyLogo3}
                alt="logo"
                className="h-[3rem] w-[3rem] "
                style={{ height: '50px', width: '50px' }}
              />
              <div style={{ paddingLeft: '24px', paddingRight: '24px' }}></div>

              <img
                src={ArmyLogo1}
                alt="logo"
                className="h-[3rem] w-[3rem] ml-2"
                style={{ height: '50px', width: '50px' }}
              />
            </div>
          </div>
          <div
            className="text-[1.5rem] font-bold uppercase"
            style={{
              paddingLeft: '15px',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.palette.mode === 'dark' ? 'white' : '#191818'
            }}
          >
            GMTS - Node
          </div>
        </div>

        <div className="flex justify-between">
          <div
            className="flex justify-between"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '',
              marginTop: '10px',
              marginBottom: '10px',
              marginRight: '24px',
              marginLeft: '24px'
            }}
          >
            <div className="text-[16px] ">
              <div className="flex justify-end">
                <div
                  className="text-right"
                  style={{
                    color: theme.palette.mode === 'dark' ? 'white' : '#191818'
                  }}
                >
                  {village.split('-')[1]}
                </div>
                <div
                  className="text-right ml-2"
                  style={{
                    color: theme.palette.mode === 'dark' ? 'white' : '#718096'
                  }}
                >
                  {'(' + village.split('-')[0] + ')'}
                </div>
              </div>
              <div
                className="font-light text-[16px] pl-2"
                style={{
                  alignContent: 'center',
                  color: theme.palette.mode === 'dark' ? 'white' : '#191818'
                }}
              >
                {node.split('-').slice(1).join(' - ')}
              </div>
            </div>

            <PlaceIcon
              style={{
                fontSize: '32px',
                marginLeft: '8px',
                color: theme.palette.mode === 'dark' ? 'white' : '#191818'
              }}
              className=""
            />
          </div>
          <ChangeVillage />
          <div className="flex justify-between" style={{ padding: '0px 16px' }}>
            <div
              className="flex justify-between"
              style={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #4E4E4D',
                  borderRadius: '100px',
                  padding: '8px',
                  margin: '0px 4px 0px 0px'
                }}
              >
                <div
                  className="capitalize text-white"
                  style={{ color: theme.palette.mode === 'dark' ? 'white' : '#191818' }}
                >
                  {userType}
                </div>
                <img src={ArmyLogo2} alt="logo" className="h-[1.50rem] w-[1.50rem] rounded-full " />
              </div>
            </div>
            <div className="flex justify-between" style={{ justifyContent: 'center', alignItems: 'center' }}>
              <div className="flex" style={{ border: '1px solid #4E4E4D', borderRadius: '100px' }}>
                <div
                  className="flex justify-between"
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <label
                    htmlFor="fileInputHeader"
                    style={{ cursor: 'pointer', padding: '8px', margin: '0px 4px' }}
                    className="custom-button rounded hover:text-green-700"
                  >
                    Import
                  </label>
                  <input
                    id="fileInputHeader"
                    style={{ display: 'none' }}
                    type="file"
                    // accept=".csv,.xlsx,.xls,.zip"
                    onChange={handleFileImportHeader}
                  />
                  {loading && <LoadingIcon className="text-green-500" />}
                </div>
                <div className="flex justify-between items-center	"> | </div>
                <div
                  className="flex justify-between "
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    type="button"
                    onClick={handleExport}
                    className={`rounded hover:text-green-700 ${
                      theme.palette.mode === 'dark' ? 'text-white' : 'text-[#191818]'
                    } px-2 mx-1`}
                  >
                    Export
                  </button>
                  {exportLoading && (
                    <div>
                      <LoadingIcon className="text-green-500" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="flex justify-between"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: ''
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #4E4E4D',
                  borderRadius: '100px',
                  padding: '8px',
                  margin: '0px 0px 0px 4px'
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setAuthenticated(false);
                    navigate('/login');
                    localStorage.removeItem('authenticated');
                    localStorage.removeItem('userType');
                    localStorage.clear();
                  }}
                  className="rounded"
                  style={{
                    color: theme.palette.mode === 'dark' ? 'white' : '#191818',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    marginRight: '8px' // Optional: Add some space between button text and icon
                  }}
                >
                  Logout
                </button>
                <LogoutLogo style={{ fill: theme.palette.mode === 'dark' ? 'white' : '#191818' }} />
              </div>
            </div>
            <div
              className="flex justify-between"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: ''
              }}
            >
              <div>
                <button
                  className="cursor-pointer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #4E4E4D',
                    borderRadius: '100px',
                    padding: '8px',
                    margin: '0px 0px 0px 4px'
                  }}
                  onClick={handleInfoClick}
                >
                  Support
                </button>
                <Modal
                  visible={showInfo}
                  onCancel={handleModalClose}
                  footer={[
                    <Button key="ok" type="primary" onClick={handleModalClose} style={{ backgroundColor: 'blue' }}>
                      OK
                    </Button>
                  ]}
                >
                  <div className="">
                    <div className="flex justify-start py-1" style={{ padding: '12px', fontSize: 'calc(1em + 2pt)' }}>
                      <strong> Help and Support </strong>
                    </div>
                    <div className="flex justify-start py-1" style={{ paddingLeft: '12px', paddingBottom: '6px' }}>
                      <PhoneIcon style={{ marginRight: '8px' }} />
                      <strong>Contact Number :</strong>&nbsp;+91 8368773478
                    </div>
                    <div className="flex justify-start py-1" style={{ paddingLeft: '12px', paddingTop: '6px' }}>
                      <WhatsAppIcon style={{ marginRight: '8px' }} />
                      <strong>Whatsapp : </strong>&nbsp;+91 8130774322
                    </div>
                    <div className="px-4 my-2 font-bold text-lg ring-1 ring-black rounded-lg w-fit">
                      <Link to={'/Manual.pdf'} target="_blank" rel="noopener noreferrer">
                        View Manual
                      </Link>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div
            className="flex justify-between"
            style={{
              justifyContent: 'center',
              // paddingLeft: "6px",
              // paddingRight: "20px",
              alignItems: 'center',
              backgroundColor: ''
            }}
          >
            <button
              type="button"
              onClick={handlePrint}
              className="rounded"
              style={{
                color: theme.palette.mode === 'dark' ? 'white' : '#191818',
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                marginRight: '8px' // Optional: Add some space between button text and icon
              }}
            >
              <PrintIcon
                fontSize="large"
                style={{
                  color: 'black'
                }}
              />
            </button>
            <MaterialUISwitch
              checked={theme.palette.mode === 'dark'}
              onChange={toggleTheme}
              inputProps={{ 'aria-label': 'dark mode checkbox' }}
            />
          </div>
        </div>
      </div>
      {lastImportTime && (
        <div className="flex justify-end" style={{ paddingRight: '24px', fontSize: 'calc(1em - 2pt)' }}>
          <strong>Last Imported : </strong>&nbsp;
          {format(lastImportTime, 'dd MMMM yyyy, h.mm a')}
        </div>
      )}

      <hr style={{ borderColor: theme.palette.mode === 'dark' ? '#4E4E4D' : 'gray' }} />
    </>
  );
};

export default Header;
