import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VillageContext from '../context/VillageContext';
import { notification } from 'antd';
import logo from '../images/army-logo-2.png';
import { NODE_NAME } from '../constants';
import Cookies from 'js-cookie';
const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [nodeName, setNodeName] = useState('');
  const { authenticated, setAuthenticated, setUserType } = useContext(VillageContext);

  const navigate = useNavigate('/');

  useEffect(() => {
    setAuthenticated(false);
  }, []);

  useEffect(() => {
    const n = Cookies.get('node');
    if (n) setNodeName(n);
    else setNodeName('node-NODE 10');
  });

  const handleLogin = (e) => {
    e.preventDefault();
    if (username == 'user' && password == 'user') {
      setAuthenticated(true);
      setUserType('user');
      localStorage.setItem('authenticated', true);
      localStorage.setItem('userType', 'user');

      navigate('/');
    } else if (username == 'admin' && password == 'admin') {
      setAuthenticated(true);
      setUserType('admin');
      localStorage.setItem('authenticated', true);
      localStorage.setItem('userType', 'admin');
      navigate('/');
    } else {
      notification.warning({
        message: 'User name or password is wrong!!'
      });
    }

    // Here you can add your login logic
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[] text-blue-900">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center mb-[1rem]">
          <img src={logo} className="w-[8rem] h-[8rem]" />
        </div>
        <h2 className="text-3xl font-semibold mb-6 text-center">GMTS - {nodeName.split('-').slice(1).join(' - ')}</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-6">
            <label htmlFor="username" className="block text-gray-700 text-left font-medium mb-1">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700 text-left  font-medium mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
              required
            />
          </div>
          <div className="mb-6">
            <button
              type="submit"
              className="w-full bg-indigo-500 text-white py-3 rounded-md hover:bg-indigo-600 transition duration-300"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
