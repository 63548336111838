import { Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { BACKEND_URL } from '../constants';

const DependantFields = ({ i, addDepFunc, dependants, type, setDependants, values, resident, removeDepFunc }) => {
  const [newDependant, setNewDependant] = useState({});
  const [villages, setVillages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    getVillageList();
  }, []);

  useEffect(() => {
    setNewDependant(values);
  }, [dependants, values]);

  useEffect(() => {
    if (newDependant.fName) {
      addDepFunc(newDependant);
    }
  }, [newDependant]);

  useEffect(() => {
    if (!!searchTerm) {
      const delaySearch = setTimeout(() => {
        getPeopleData();
      }, 500);

      return () => clearTimeout(delaySearch);
    }
  }, [searchTerm]);

  const getPeopleData = () => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getUserInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          f_name: searchTerm.trim()
          //   ...filters,
          //   village_ids,
        },
        limit: 100
        // page: currentPage,
      })
    })
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((elem) => {
          return {
            value: elem.user_id + ',' + elem.f_name + ' ' + elem.l_name,
            label: elem.f_name + ' ' + elem.l_name,
            photo: elem.imagebase64,
            village: elem?.village?.v_name,

            fName: elem.f_name,
            lName: elem.l_name,
            contact: elem.contact,
            villageId: elem.village_id,
            personId: elem.user_id,
            city: elem.city,
            state: elem.state,
            pincode: elem.pincode,
            aadhar: elem.aadhar
          };
        });
        setPeople(options);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const getVillageList = () => {
    fetch(`${BACKEND_URL}/getVillageDetails`)
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((elem) => {
          return {
            label: elem.v_name,
            value: elem.village_id
          };
        });
        setVillages(options);
      })
      .catch((err) => console.log(err));
  };

  const handleFieldsChange = (key, value) => {
    const depend = { ...newDependant };
    depend[key] = value;
    setNewDependant(depend);
    dependants.splice(i, 1, depend);
    setDependants(dependants);
  };

  const getPersonName = (id) => {
    fetch(`${BACKEND_URL}/getUserInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          use_id: id
          //   ...filters,
          //   village_ids,
        },
        limit: 100
        // page: currentPage,
      })
    });
  };

  const dependantOptions = [
    { value: 'father', label: 'Father' },
    { value: 'mother', label: 'Mother' },
    { value: 'husband', label: 'Husband' },
    { value: 'wife', label: 'Wife' },
    { value: 'son', label: 'Son' },
    { value: 'daughter', label: 'Daughter' }
  ];

  const nonDependantOptions = [
    { value: 'brother', label: 'Brother' },
    { value: 'sister', label: 'Sister' },
    { value: 'friend', label: 'Friend' },
    { value: 'cousin', label: 'Cousin' }
  ];

  const renderCustomOptions = (option) => {
    return (
      <span className="flex items-center justify-between">
        <span>
          <div className="font-regular">{option.label}</div>
          <div className="font-thin text-gray-500">{option.data?.village}</div>
        </span>
        <img className="h-[4rem] w-[4rem] object-contain" src={option.data?.photo} alt="person-photo" />
      </span>
    );
  };

  const filterVillageOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Spin spinning={loading}>
      <div className="flex pb-3 mb-2">
        <span className="mr-2 font-bold">{i + 1} </span>
        <div className="flex flex-wrap">
          <div className="flex w-full ">
            <label className="w-1/5 mr-2 ">
              <div className="text-left">Relation:</div>
              <Select
                options={type == 'no-depend' ? nonDependantOptions : dependantOptions}
                value={newDependant?.relative ? newDependant?.relative : null}
                // disabled={values.fName && resident}
                placeholder="Search Dependants"
                className="w-full text-left !text-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                onChange={(e) => handleFieldsChange('relative', e)}
              />
            </label>

            <label className="w-1/5 mr-2 ">
              <div className="text-left">Search Person:</div>
              {console.log(people, 'people')}
              <Select
                options={people}
                suffixIcon={<SearchIcon className="h-[10px] w-[10px] " />}
                optionRender={renderCustomOptions}
                placeholder="Search Name"
                onSearch={(e) => setSearchTerm(e)}
                showSearch
                value={newDependant?.personId ? newDependant?.label : null}
                disabled={values.fName && resident} // value={newDependant?.personId}
                className="w-full text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                onChange={(e) => {
                  const depenData = people.find((person) => person.value == e);
                  depenData.value = depenData?.value?.split(',')[0];

                  setNewDependant({
                    ...newDependant,
                    ...depenData
                  });
                }}
              >
                {people.map((person) => {
                  <Option key={person.value} value={person.value}>
                    {renderCustomOptions(person)}
                  </Option>;
                })}
              </Select>
            </label>

            <label className="w-1/5 mr-2">
              <div className="text-left">First Name:</div>
              <input
                placeholder="First Name"
                value={newDependant.fName ? newDependant.fName : ''}
                // disabled={values.fName && resident}
                onChange={(e) => handleFieldsChange('fName', e.target.value)}
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
            <label className="w-1/5 mr-2">
              <div className="text-left">Last Name:</div>
              <input
                placeholder="Last Name"
                value={newDependant.lName ? newDependant.lName : ''}
                // disabled={values.fName && resident}
                onChange={(e) => handleFieldsChange('lName', e.target.value)}
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
            <label className="w-1/5 mr-2">
              <div className="text-left">Contact:</div>
              {console.log(newDependant, 'newDepan')}
              <input
                value={newDependant?.contact ? newDependant?.contact : ''}
                // disabled={values.fName && resident}
                onChange={(e) => handleFieldsChange('contact', e.target.value)}
                placeholder="Contact"
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
          </div>
          <div className="flex w-full mt-2">
            <label className="w-1/5 mr-2">
              <div className="text-left">Village:</div>
              <Select
                showSearch
                options={villages}
                value={newDependant?.villageId ? newDependant?.villageId : null}
                // disabled={values.fName && resident}
                placeholder="Search Village"
                className="w-full text-left bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
                filterOption={filterVillageOption}
                onChange={(e) => handleFieldsChange('villageId', e)}
              />
            </label>
            <label className="w-1/5 mr-2">
              <div className="text-left">City:</div>
              <input
                value={newDependant?.city ? newDependant?.city : ''}
                onChange={(e) => handleFieldsChange('city', e.target.value)}
                placeholder="City"
                // disabled={values.fName && resident}
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
            <label className="w-1/5 mr-2">
              <div className="text-left">State:</div>
              <input
                value={newDependant?.state ? newDependant?.state : ''}
                onChange={(e) => handleFieldsChange('state', e.target.value)}
                placeholder="State"
                // disabled={values.fName && resident}
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
            <label className="w-1/5 mr-2">
              <div className="text-left">Pincode:</div>
              <input
                value={newDependant?.pincode ? newDependant?.pincode : ''}
                // disabled={values.fName && resident}
                onChange={(e) => handleFieldsChange('pincode', e.target.value)}
                placeholder="Pincode"
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
            <label className="w-1/5 mr-2">
              <div className="text-left">Aadhaar:</div>
              <input
                value={newDependant?.aadhar ? newDependant?.aadhar : ''}
                // disabled={values.fName && resident}
                onChange={(e) => handleFieldsChange('aadhar', e.target.value)}
                placeholder="Aadhar"
                className="border-2 h-[2rem] w-full text-left px-2 rounded focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </label>
          </div>
        </div>
      </div>
      {dependants.length > 1 ? (
        <div className="flex justify-end mb-3">
          <button
            className="px-2 py-1 text-red-600 border-2 border-red-500 rounded "
            type="button"
            onClick={() => {
              setNewDependant({});
              removeDepFunc();
            }}
          >
            Remove
          </button>
        </div>
      ) : null}
    </Spin>
  );
};

export default DependantFields;
