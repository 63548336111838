export function addIfDoesntExist(list, element, property, end = true) {
  const index = list.findIndex((e) => e[property] == element[property]);
  if (index == -1) {
    if (end) {
      list = [...list, element];
    } else {
      list = [element, ...list];
    }
  }
  return list;
}

export function hasElementWithProperty(list, key, value) {
  const index = list.findIndex((e) => e[key] == value);
  return index != -1;
}
