import { FINGERPRINT_URL_INSECURE } from '../constants';

var uri = FINGERPRINT_URL_INSECURE; //Non-Secure

var KeyFlag = '';
var isGetSuccess = false;

async function PrepareScanner() {
  try {
    if (!isGetSuccess) {
      var resInfo = await GetMFS100Client('info');
      if (!resInfo.httpStaus) {
        //alert(resInfo.err);
        return false;
      } else {
        isGetSuccess = true;
      }

      if (KeyFlag != null && KeyFlag != 'undefined' && KeyFlag.length > 0) {
        var MFS100Request = {
          Key: KeyFlag
        };
        var jsondata = JSON.stringify(MFS100Request);
        PostMFS100Client('keyinfo', jsondata);
      }
    }
  } catch (e) {
    console.log(e);
  }
  return true;
}

async function PostMFS100Client(method, jsonData) {
  async function postData(uri, method, jsonData) {
    try {
      const response = await fetch(uri + method, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: jsonData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      return { httpStaus: true, data: data };
    } catch (error) {
      return { httpStaus: false, error: error.message };
    }
  }

  const res = await postData(uri, method, jsonData);
  console.log(res, 'new res');

  return res;
}

async function GetMFS100Client(method) {
  var res;
  async function getData(uri, method) {
    try {
      const response = await fetch(uri + method, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // setThumbLoading(false);
      console.log(data, 'dataaaa');
      return { httpStaus: true, data: data };
    } catch (error) {
      // setThumbLoading(false);

      return { httpStaus: false, error: error.message };
    }
  }

  res = await getData(uri, method);

  return res;
}

export function GetMFS100Info() {
  KeyFlag = '';
  return GetMFS100Client('info');
}

export async function CaptureFinger(quality, timeout, setThumbLoading) {
  if (!(await PrepareScanner())) {
    return getFalseRes();
  }
  var MFS100Request = {
    Quality: quality,
    TimeOut: timeout
  };

  var jsondata = JSON.stringify(MFS100Request);
  console.log(jsondata, 'mfs');

  return await PostMFS100Client('capture', jsondata, setThumbLoading);
}

export async function MatchFinger(quality, timeout, GalleryFMR) {
  if (!(await PrepareScanner())) {
    return getFalseRes();
  }
  var MFS100Request = {
    Quality: quality,
    TimeOut: timeout,
    GalleryTemplate: GalleryFMR,
    BioType: 'FMR' // you can paas here BioType as "ANSI" if you are using ANSI Template
  };

  var jsondata = JSON.stringify(MFS100Request);
  return PostMFS100Client('match', jsondata);
}

export async function VerifyFinger(ProbFMR, GalleryFMR) {
  if (!(await PrepareScanner())) {
    console.log('here');
    return getFalseRes();
  }
  var MFS100Request = {
    ProbTemplate: ProbFMR,
    GalleryTemplate: GalleryFMR,
    BioType: 'FMR' // you can paas here BioType as "ANSI" if you are using ANSI Template
  };
  var jsondata = JSON.stringify(MFS100Request);
  console.log(GalleryFMR, 'gallart');

  return PostMFS100Client('verify', jsondata);
}

function GetMFS100KeyInfo(key) {
  KeyFlag = key;
  if (!PrepareScanner()) {
    return getFalseRes();
  }
  var MFS100Request = {
    Key: key
  };
  var jsondata = JSON.stringify(MFS100Request);
  return PostMFS100Client('keyinfo', jsondata);
}

function GetPidData(BiometricArray) {
  if (!PrepareScanner()) {
    return getFalseRes();
  }
  var req = new MFS100Request(BiometricArray);
  var jsondata = JSON.stringify(req);
  return PostMFS100Client('getpiddata', jsondata);
}
function GetProtoPidData(BiometricArray) {
  if (!PrepareScanner()) {
    return getFalseRes();
  }
  var req = new MFS100Request(BiometricArray);
  var jsondata = JSON.stringify(req);
  return PostMFS100Client('getppiddata', jsondata);
}
function GetRbdData(BiometricArray) {
  if (!PrepareScanner()) {
    return getFalseRes();
  }
  var req = new MFS100Request(BiometricArray);
  var jsondata = JSON.stringify(req);
  return PostMFS100Client('getrbddata', jsondata);
}
function GetProtoRbdData(BiometricArray) {
  if (!PrepareScanner()) {
    return getFalseRes();
  }
  var req = new MFS100Request(BiometricArray);
  var jsondata = JSON.stringify(req);
  return PostMFS100Client('getprbddata', jsondata);
}

function getHttpError(jqXHR) {
  var err = 'Unhandled Exception';
  if (jqXHR.status === 0) {
    err = 'Service Unavailable';
  } else if (jqXHR.status == 404) {
    err = 'Requested page not found';
  } else if (jqXHR.status == 500) {
    err = 'Internal Server Error';
  }
  //   else if (thrownError === "parsererror") {
  //     err = "Requested JSON parse failed";
  //   } else if (thrownError === "timeout") {
  //     err = "Time out error";
  //   } else if (thrownError === "abort") {
  //     err = "Ajax request aborted";
  //   }
  else {
    err = 'Unhandled Error';
  }
  return err;
}

/////////// Classes

function Biometric(BioType, BiometricData, Pos, Nfiq, Na) {
  this.BioType = BioType;
  this.BiometricData = BiometricData;
  this.Pos = Pos;
  this.Nfiq = Nfiq;
  this.Na = Na;
}

function MFS100Request(BiometricArray) {
  this.Biometrics = BiometricArray;
}

function getFalseRes() {
  var res;
  res = { httpStaus: false, err: 'Error while calling service' };
  return res;
}
