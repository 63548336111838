import Webcam from 'react-webcam';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Checkbox, Radio, notification } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import ManualSearch from '../components/ManualSearch';
// import Fingerprint from "../components/MatchFingerPrint";
import { Spin } from 'antd';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VillageContext from '../context/VillageContext';
import Rtsp from '../components/Rtsp';
// import { Add, Delete, IndeterminateCheckBoxRounded } from "@mui/icons-material";
// import useWebSocket, { ReadyState } from "react-use-websocket";
import ViewDocumentModel from '../Modals/ViewDocumentModel';
import MatchFingerPrint from '../components/MatchFingerPrint';
import { BACKEND_URL, FRS_URL, PYTHON_BACKEND_URL, PYTHON_FRS_URL } from '../constants';
import { ThemeContext } from '../index';
import { io } from 'socket.io-client';
import LiveDetectedEntry from '../components/LiveDetectedEntry';
import { addIfDoesntExist, hasElementWithProperty } from '../utils/utility';
import Cookies from 'js-cookie';
const Home = () => {
  const [imgUrl, setImgUrl] = useState(null);
  const [searchingFrs, setSerachingFrs] = useState(false);
  const [lastFaceDetectedTime, setLastFaceDetectedTime] = useState(new Date().getTime());
  const [frsUsers, setfrsUsers] = useState([]);
  const [residentData, setResidentData] = useState();
  const [txnType, setTxnType] = useState('Entry');
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('frs');
  const [isOutsider, setIsOutsider] = useState(false);
  const [newUser, setNewUser] = useState({});
  const [error, setError] = useState(false);
  const [villages, setVillages] = useState([]);
  const [remarks1, setRemarks1] = useState('');
  const [remarks2, setRemarks2] = useState('');
  const [remarks3, setRemarks3] = useState('');
  const [remarks4, setRemarks4] = useState('');
  const [remarks, setRemarks] = useState('');
  const [category, setCategory] = useState('');
  const [categoryRemarks, setCategoryRemarks] = useState('');
  const [purposeOfVisit, setPurposeOfVisit] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleMakeModel, setVehicleMakeModel] = useState('');
  const [vehicleRemarks, setVehicleRemarks] = useState('');
  // const [selecetedCamera, setSelectedCamera] = useState("web");
  const [selecetedCamera, setSelectedCamera] = useState(() => {
    const savedCamera = Cookies.get('cameraValue');
    return savedCamera ? savedCamera : 'web';
  });
  const [consentGiven, setConsentGiven] = useState(true);
  const [rightThumb, setRightThumb] = useState();
  const [registeredThumb, setRegisteredThumb] = useState();
  const { village, setVillage, node, setNode } = useContext(VillageContext);
  const currentVillage = village;
  const currentNode = node;
  const { theme } = useContext(ThemeContext);
  const cameraOptions = [
    {
      label: (
        <span className="text-lg" style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }}>
          Web Cam
        </span>
      ),
      value: 'web'
    },
    {
      label: (
        <span className="text-lg" style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }}>
          IP Camera
        </span>
      ),
      value: 'ipCam'
    },
    {
      label: (
        <span className="text-lg" style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }}>
          Body Camera
        </span>
      ),
      value: 'bodyCam'
    }
  ];
  const [faceImage, setFaceImage] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getVillageList();
    if (location?.state?.image) setImgUrl(location?.state?.image);
  }, []);

  useEffect(() => {
    const camera = Cookies.get('cameraValue');
    if (camera) setSelectedCamera(camera);
  }, []);

  useEffect(() => {
    const socket = io(PYTHON_BACKEND_URL);

    socket.on('face_stream', (frameEncoded) => {
      console.log('searching frs');

      const face = 'data:image/jpg;base64,' + frameEncoded;

      setFaceImage(face);

      // Detect one face at a time
      setSerachingFrs((isSearching) => {
        if (!isSearching) {
          try {
            searchResidentFrsV2(face);
            return true;
          } catch (e) {
            console.log(e);
          }
        }
        return false;
      });

      console.log('face receivied: ' + new Date().getTime());
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getVillageList = () => {
    fetch(`${BACKEND_URL}/getVillageDetails`)
      .then((res) => res.json())
      .then((data) => setVillages(data.result))
      .catch((err) => console.log(err));
  };

  const webCamRef = useRef();

  const videoConstraints = {
    // width: 540,
    outerHeight: 1000,
    facingMode: 'environment'
  };

  const getRegisteredFingerPrint = (uid) => {
    const query = {
      q: {
        user_id: uid
      }
    };
    fetch(`${BACKEND_URL}/getFingerprint`, {
      method: 'POST',
      body: JSON.stringify(query),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => setRegisteredThumb(data.result[0]))
      .catch((err) => console.log(err));
  };

  const getUserById = (id) => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getUserInfoById/${id}`)
      // fetch(
      //   `${BACKEND_URL}/getUserInfoById/N0006-1711629068465`
      // )
      // fetch(`${BACKEND_URL}/getUserInfoByIdV4/OT8078`)
      .then((res) => res.json())
      .then((data) => {
        setResidentData(data.result);
        getRegisteredFingerPrint(id);
        // getRegisteredFingerPrint("N0006-1711629068465");
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const createTransaction = () => {
    const reqJson = {
      user_id: residentData?.user_id,
      village_id: currentVillage.split('-')[0],
      node_id: node.split('-')[0],
      entry_or_exit_at: moment(new Date()).tz('Asia/Kolkata'),
      imagebase64: imgUrl.slice(22),
      entry_or_exit: txnType == 'Entry' ? 'Entry' : 'Exit',
      category_remarks: categoryRemarks,
      category: category,
      purpose_of_visit: purposeOfVisit,
      vehicle_type: vehicleType,
      vehicle_make_model: vehicleMakeModel,
      vehicle_remarks: vehicleRemarks,
      remarks1: remarks1,
      remarks2: remarks2,
      remarks3: remarks3,
      remarks4: remarks4
    };

    setLoading(true);
    fetch(`${BACKEND_URL}/createTransaction`, {
      method: 'POST',
      body: JSON.stringify(reqJson),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode == '201') {
          const type = txnType == 'Entry' ? 'Entry' : 'Exit';
          setImgUrl('');
          setResidentData(null);
          notification.success({
            message: `${type} marked`
          });
          if (newUser?.category) {
            updateCategory();
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateCategory = () => {
    fetch(`${BACKEND_URL}/updateUserInfoV4/${residentData?.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        category: newUser?.category
      })
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const searchResidentFrsV2 = (src) => {
    fetch(`${PYTHON_FRS_URL}/search_face`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        base64_image: src
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else {
          res.json().then((e) => console.log(e));
          throw new Error(`HTTP error! status: ${res.status}`);
        }
      })
      .then(async (data) => {
        const id = data?.user_id;
        const frdata = {};
        frdata.createdAt = new Date().getTime();
        frdata.img = src;
        console.log('frs searched: ' + id);

        setfrsUsers((prevUsers) => {
          if (id) {
            const index = prevUsers.findIndex((e) => e.id == id);
            console.log('frs cached: ' + index);

            if (!hasElementWithProperty(prevUsers, 'id', id)) {
              frdata.id = id;
              searchUserById(id, frdata);
            }
            return prevUsers;
          } else {
            return addIfDoesntExist(prevUsers, frdata, 'createdAt');
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResidentIdV2 = (src) => {
    setLoading(true);
    fetch(`${PYTHON_FRS_URL}/search_face`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        base64_image: src
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else {
          res.json().then((e) => console.log(e));
          throw new Error(`HTTP error! status: ${res.status}`);
        }
      })
      .then((data) => {
        console.log('get resident v2');
        console.log(data);
        const id = data?.user_id;
        if (id && id != 'NotFound') {
          getUserById(id);
        } else {
          setResidentData(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const searchUserById = async (id, frData) => {
    fetch(`${BACKEND_URL}/getUserInfoById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        frData.data = data.result;
        if (data.result)
          setfrsUsers((prevUsers) => {
            //check again if it exists
            const index = prevUsers.findIndex((e) => e.id == frData.id);
            console.log('db searched for, ' + id + ' cache: ' + index);
            return index == -1 ? [frData, ...prevUsers] : prevUsers;
          });
      })
      .catch((err) => console.log(err));
  };

  // useEffect to periodically check and remove users
  useEffect(() => {
    const interval = setInterval(() => {
      // Filter out users whose created time is more than 5 seconds ago
      setfrsUsers((prevUsers) => prevUsers.filter((user) => Date.now() - user.createdAt < (user.id ? 15000 : 1000)));
    }, 1000); // Check every second

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []); // Run effect whenever frsUsers state changes

  const getResidentId = (src) => {
    fetch(`${FRS_URL}/get_resident_id`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        base64_image: src?.slice(22)
      })
    })
      .then((res) => res.json())
      .then((data) => {
        const id = data?.resident_id;
        if (id && id != 'NotFound') {
          getUserById(id);
        } else {
          setResidentData(null);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const captureImage = useCallback(() => {
    if (consentGiven) {
      const src = webCamRef.current.getScreenshot();
      // setLoading(true);
      setImgUrl(src);

      getResidentIdV2(src);
    } else {
      notification.warning({
        message: 'Please get consent from the concerned person!'
      });
    }

    // getRandomId();
  }, [webCamRef, consentGiven]);

  const captureImageFromIp = (imgUrl) => {
    if (consentGiven) {
      // setLoading(true);
      setImgUrl(faceImage);

      getResidentIdV2(faceImage);
    } else {
      notification.warning({
        message: 'Please get consent from the concerned person!'
      });
    }
  };

  return (
    <Spin size="large" spinning={loading} className="mt-[9rem]">
      <div className="px-[2rem]    w-full  app-container overflow-">
        <div className=" flex justify-between items-center border-b-2  mb-2 mt-2 ">
          <div className="text-xl text-gray-500">
            <button
              onClick={() => setSelectedTab('frs')}
              className={`${selectedTab == 'frs' ? 'text-blue-600 border-blue-600 border-b-4 ' : ''} py-3 px-[5rem]  `}
            >
              FRS
            </button>
            <button
              onClick={() => setSelectedTab('manual')}
              className={`${selectedTab == 'manual' ? ' text-blue-600 border-blue-600 border-b-4' : ''} px-4   py-3`}
            >
              Manual Search
            </button>
          </div>
          <div className="flex items-center">
            <Radio.Group
              options={cameraOptions}
              onChange={(e) => {
                setSelectedCamera(e.target.value);
                Cookies.set('cameraValue', e.target.value, { expires: 365 });
                setImgUrl('');
              }}
              className="text-2xl "
              value={selecetedCamera}
            />
            <Link to={'/new'}>
              <button className=" px-3 py-2   rounded bg-blue-500  text-white">Add New Person</button>
            </Link>
          </div>
        </div>

        {selectedTab == 'frs' ? (
          <div className="  flex ">
            <div className="w-1/2 flex flex-col">
              {selecetedCamera == 'web' ? (
                <div>
                  <Webcam
                    ref={webCamRef}
                    // audio={true}
                    screenshotFormat="image/png"
                    videoConstraints={videoConstraints}
                    mirrored={false}
                  />
                  <button
                    onClick={captureImage}
                    className="mt-[1rem]  px-[2rem] py-[.5rem] text-xl self-end bg-blue-500 text-white rounded "
                  >
                    Capture & Register
                  </button>
                </div>
              ) : null}
              {selecetedCamera == 'ipCam' ? (
                <div>
                  <Rtsp
                    capture={captureImageFromIp}
                    // backToWebCam={() => setSelectedCamera("web")}
                  />
                </div>
              ) : null}
              {selecetedCamera == 'bodyCam' ? (
                <div>
                  <Rtsp
                    capture={captureImageFromIp}
                    // backToWebCam={() => setSelectedCamera("web")}
                  />
                </div>
              ) : null}

              <div className="mb-2 mt-4 font-bold flex items-start">
                <Checkbox
                  checked={consentGiven}
                  className="flex items-start"
                  onChange={(e) => setConsentGiven(e.target.checked)}
                ></Checkbox>
                <span className="text-left ml-1 cursor-pointer" onClick={(e) => setConsentGiven(!consentGiven)}>
                  {' '}
                  I acknowledge that the individual has granted permission to collect their photographs, personal
                  information, fingerprints, and documents.
                </span>
              </div>
            </div>
            {console.log(registeredThumb, 'thumb')}

            {selecetedCamera != 'web' && !(residentData || imgUrl) && (
              <div className="flex" style={{ flexDirection: 'column' }}>
                {frsUsers.map((e, i) => (
                  <LiveDetectedEntry
                    BACKEND_URL={BACKEND_URL}
                    imgUrl={e.img}
                    residentData={e.data}
                    frData={e}
                    newUser={newUser}
                    setNewUser={setNewUser}
                    location={location}
                    // key={i}
                    key={e.id ?? e.createdAt}
                  />
                ))}
              </div>
            )}

            {loading ? null : (
              <>
                {imgUrl ? (
                  residentData ? (
                    <div className="w-1/2 ">
                      <div className="mx-[1rem] flex ">
                        <div className="relative">
                          <img src={imgUrl} className="h-[20-rem] w-[20rem] object-contain bg-[blue]" />
                          {residentData?.category == 'Anti-National' || residentData?.category == 'Anti National' ? (
                            <div className="absolute bottom-0 w-full h-[3rem] text-xl text-white pt-2 bg-red-500 bg-opacity-50	 backdrop-blur-sm">
                              Anti National
                            </div>
                          ) : residentData?.wanted ? (
                            <div className="absolute bottom-0 w-full h-[3rem] text-xl text-white pt-2 bg-red-500 bg-opacity-50	 backdrop-blur-sm">
                              Wanted
                            </div>
                          ) : null}
                        </div>
                        <div className="ml-5 text-[1rem] text-left w-1/2">
                          <div className="font-thin  mb-[5px]  ">
                            <span className="">First Name: </span>
                            <span className="font-medium text-xl text-gray-700 dark:text-white">
                              {residentData?.f_name}
                            </span>
                          </div>
                          <div className="font-thin  mb-[5px]">
                            <span>Last Name:</span>
                            <span className="text-gray-700 text-xl font-medium dark:text-white">
                              {' '}
                              {residentData?.l_name}
                            </span>
                          </div>
                          <div className="flex">
                            <div className="font-thin  mb-[5px]">
                              <span>Gender: </span>
                              <span className="font-medium text-xl">{residentData?.gender}</span>
                            </div>
                            <div className="font-thin  mb-[5px] ml-5">
                              <span>Age: </span>
                              <span className="font-medium text-xl">{residentData?.age ? residentData?.age : '-'}</span>
                            </div>
                          </div>

                          <div className="font-thin  mb-[5px]">
                            <span>DOB: </span>
                            <span className="font-medium text-xl">
                              {residentData?.dob ? moment.utc(residentData?.dob).local().format('DD-MM-YYYY') : '-'}
                            </span>
                          </div>

                          <div className="font-thin  mb-[5px]">
                            <span>Contact: </span>
                            <span className="font-medium text-xl">
                              {residentData?.contact ? residentData?.contact : '-'}
                            </span>
                          </div>
                          <div className="font-thin  mb-[5px]">
                            <span>Aadhaar: </span>
                            <span className="font-medium text-xl">
                              {residentData?.aadhar ? residentData?.aadhar : '-'}
                            </span>
                          </div>
                          <div className="font-thin  mb-[5px]">
                            Address:{' '}
                            <span className="font-medium text-xl">
                              {residentData?.address ? residentData?.address : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex content-between text-[1rem] mt-5">
                        <div className="ml-5 text-left  w-[20rem]">
                          <div className="font-thin  mb-[5px]">
                            <span>Village ID: </span>
                            <span className="font-medium  text-xl">
                              {residentData?.village?.village_id ? residentData?.village?.village_id : '-'}
                            </span>
                          </div>

                          <div className="font-thin  mb-[5px]">
                            <span>Village Code: </span>
                            <span className="font-medium text-xl">
                              {residentData?.village?.v_code ? residentData?.village?.v_code : '-'}
                            </span>
                          </div>
                          <div className="font-thin  mb-[5px]">
                            <span>Village Name: </span>
                            <span
                              className={`font-medium ${
                                residentData?.village.village_id === currentVillage ? '' : 'text-red-500'
                              }`}
                            >
                              {residentData?.village.v_name ? residentData?.village.v_name : '-'}
                            </span>
                          </div>

                          <div className="w-full home-accordion">
                            {residentData?.documentURL ? (
                              <div>
                                <div className="text-xl font-bold">Documents </div>
                                <div className="flex w-full">
                                  {residentData?.documentURL?.map((url, i) => {
                                    return (
                                      <div className="relative  mr-2   w-[7rem]" key={i}>
                                        <ViewDocumentModel
                                          // image={"data:image/png;base64," + url}
                                          image={url}
                                          deleteDocument={() => {
                                            notification.warning({
                                              message: 'You are not authorized to delete this document!!'
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          {/* category for person entry & exit ---end code */}

                          {/* Adding 1 -4 remarks input field start here  */}
                        </div>
                        <div className="ml-5 text-left ">
                          <div className="font-thin  mb-[5px]">
                            <span>District: </span>
                            <span className="font-medium text-xl">
                              {residentData?.village.v_district ? residentData?.village.v_district : '-'}
                            </span>
                          </div>
                          <div className="font-thin  mb-[5px]">
                            <span>State: </span>
                            <span className="font-medium text-xl">
                              {residentData?.village.v_state ? residentData?.village.v_state : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="w-full home-accordion">
                        <Accordion className="accordion-container">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordion-summary"
                          >
                            <span className="accordion-summary-text">Remarks</span>
                          </AccordionSummary>
                          <AccordionDetails className="accordion-details">
                            <div className="grid grid-cols-2 gap-4">
                              <div className="mb-3 pr-3">
                                <label
                                  className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                                  htmlFor="remarks1"
                                >
                                  Remarks 1
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="remarks1"
                                  onChange={(e) => setRemarks1(e.target.value)}
                                  value={
                                    location.state?.user?.remarks1 ? location.state?.user?.remarks1 : newUser.remarks1
                                  }
                                  placeholder="Enter Remarks Here..."
                                  disabled={location.state?.user}
                                />
                              </div>

                              <div className="mb-3 pr-3">
                                <label
                                  className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                                  htmlFor="remarks2"
                                >
                                  Remarks 2
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="remarks2"
                                  onChange={(e) => setRemarks2(e.target.value)}
                                  value={
                                    location.state?.user?.remarks2 ? location.state?.user?.remarks2 : newUser.remarks2
                                  }
                                  placeholder="Enter Remarks Here..."
                                  disabled={location.state?.user}
                                />
                              </div>

                              <div className="mb-3 pr-3">
                                <label
                                  className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                                  htmlFor="remarks3"
                                >
                                  Remarks 3
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="remarks3"
                                  onChange={(e) => setRemarks3(e.target.value)}
                                  value={
                                    location.state?.user?.remarks3 ? location.state?.user?.remarks3 : newUser.remarks3
                                  }
                                  placeholder="Enter Remarks Here..."
                                  disabled={location.state?.user}
                                />
                              </div>

                              <div className="mb-3 pr-3">
                                <label
                                  className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                                  htmlFor="remarks4"
                                >
                                  Remarks 4
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="remarks4"
                                  onChange={(e) => setRemarks4(e.target.value)}
                                  value={
                                    location.state?.user?.remarks4 ? location.state?.user?.remarks4 : newUser.remarks4
                                  }
                                  placeholder="Enter Remarks Here..."
                                  disabled={location.state?.user}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>

                      {/* category for vehicle entry & exit ---start code */}
                      <div className="w-full home-accordion">
                        <Accordion className="accordion-container">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordion-summary"
                          >
                            <span className="accordion-summary-text">Entry/Exit Vehicle Details</span>
                          </AccordionSummary>
                          <AccordionDetails className="accordion-details">
                            <div
                              className="vehicle-container flex flex-wrap"
                              // style={{ display: "contents" }}
                            >
                              <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                                <label
                                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                                  htmlFor="grid-vehicle_type"
                                >
                                  Vehicle Type
                                </label>
                                <input
                                  className="appearance-none block w-full   border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                                  id="grid-vehicle_type"
                                  type="text"
                                  placeholder="Please enter Vehicle type"
                                  value={
                                    location.state?.user?.vehicle_type
                                      ? location.state?.user?.vehicle_type
                                      : vehicleType
                                  }
                                  disabled={location.state?.user}
                                  onChange={(e) => setVehicleType(e.target.value)}
                                />
                                {error && !newUser?.vehicle_type ? (
                                  <p className="text-red-500 text-xs italic text-left mt-1">
                                    Please fill out this field.
                                  </p>
                                ) : null}
                              </div>
                              <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                                <label
                                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                                  htmlFor="grid-vehicle_type"
                                >
                                  Vehicle Number
                                </label>
                                <input
                                  className="appearance-none block w-full   border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                                  id="grid-vehicle_type"
                                  type="text"
                                  placeholder="Please enter Vehicle type"
                                  value={vehicleNumber}
                                  disabled={location.state?.user}
                                  onChange={(e) => setVehicleNumber(e.target.value)}
                                />
                                {error && !newUser?.vehicle_type ? (
                                  <p className="text-red-500 text-xs italic text-left mt-1">
                                    Please fill out this field.
                                  </p>
                                ) : null}
                              </div>
                              <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                                <label
                                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                                  htmlFor="grid-vehicle_type"
                                >
                                  Vehicle Make Model
                                </label>
                                <input
                                  className="appearance-none block w-full   border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                                  id="grid-vehicle_type"
                                  type="text"
                                  placeholder="Please Enter Vehicle Make Model"
                                  value={
                                    location.state?.user?.vehicle_make_model
                                      ? location.state?.user?.vehicle_make_model
                                      : newUser.vehicle_make_model
                                  }
                                  disabled={location.state?.user}
                                  onChange={(e) => setVehicleMakeModel(e.target.value)}
                                />
                                {error && !newUser?.vehicle_make_model ? (
                                  <p className="text-red-500 text-xs italic text-left mt-1">
                                    Please fill out this field.
                                  </p>
                                ) : null}
                              </div>
                              <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                                <label
                                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                                  htmlFor="grid-vehicle_type"
                                >
                                  Vehicle Remarks
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="grid-vehicle_remarks"
                                  type="text"
                                  placeholder="Please enter Vehicle Remarks"
                                  style={{ width: '104%', height: '50%' }}
                                  value={
                                    location.state?.user?.vehicle_remarks
                                      ? location.state?.user?.vehicle_remarks
                                      : newUser.vehicle_remarks
                                  }
                                  disabled={location.state?.user}
                                  onChange={(e) => setVehicleRemarks(e.target.value)}
                                />
                                {error && !newUser?.vehicle_remarks ? (
                                  <p className="text-red-500 text-xs italic text-left mt-1">
                                    Please fill out this field.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      {/* category for vehicle entry & exit ---end code */}

                      <div className="w-full home-accordion">
                        <Accordion className="accordion-container">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            className="accordion-summary"
                          >
                            <span className="accordion-summary-text">Entry/Exit Visit Details</span>
                          </AccordionSummary>
                          <AccordionDetails className="accordion-details">
                            <div className="person-category-container flex flex-wrap mb-3 w-full pr-3 md:mb-3">
                              <div className="w-full">
                                <label
                                  className="block mt-2 uppercase text-left tracking-wide text-[1rem]"
                                  htmlFor="grid-state"
                                >
                                  Person Category
                                </label>
                                <div className="relative">
                                  <select
                                    className={`block appearance-none bg-white w-full mb-2 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white ${
                                      newUser?.category === 'Select Category for persons entering or exiting' ||
                                      !newUser.category
                                        ? 'text-gray-400'
                                        : ''
                                    }`}
                                    id="grid-state"
                                    disabled={location.state?.user}
                                    onChange={(e) => {
                                      setCategory(e.target.value);
                                      setNewUser({
                                        ...newUser,
                                        category: e.target.value
                                      });
                                      setIsOutsider(e.target.value === 'V5042');
                                    }}
                                    value={
                                      location.state?.user?.village?.category
                                        ? location.state?.user.village?.category
                                        : newUser.category
                                    }
                                  >
                                    <option value={''}>Please select category of person</option>
                                    <option value="Villager">Villager</option>
                                    <option value="Govt official">Govt official</option>
                                    <option value="Tourist">Tourist</option>
                                    <option value="Defence Person">Defence Person</option>
                                    <option value="Ex-Defence Person">Ex-Defence Person</option>
                                    <option value="Anti-National">Anti-National</option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className=" w-full">
                                <label
                                  className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                                  htmlFor="category_remarks"
                                >
                                  Person Remarks
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="category_remarks"
                                  onChange={(e) => setCategoryRemarks(e.target.value)}
                                  value={
                                    location.state?.user?.category_remarks
                                      ? location.state?.user?.category_remarks
                                      : newUser.category_remarks
                                  }
                                  placeholder="Enter Remarks Here..."
                                  disabled={location.state?.user}
                                />
                              </div>
                              <div className="w-full" style={{ display: 'inline' }}>
                                <label
                                  className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                                  htmlFor="purpose_of_visit"
                                >
                                  Visit Purpose
                                </label>
                                <textarea
                                  className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                  id="purpose_of_visit"
                                  style={{ width: '100%', height: '48%' }}
                                  onChange={(e) => setPurposeOfVisit(e.target.value)}
                                  value={
                                    location.state?.user?.purpose_of_visit
                                      ? location.state?.user?.purpose_of_visit
                                      : newUser.purpose_of_visit
                                  }
                                  placeholder="Enter Visit Purpose Here..."
                                  disabled={location.state?.user}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>

                      <div className="flex w-full justify-between border-t-2 ml-3 mt-3">
                        <MatchFingerPrint
                          type={'Verify'}
                          rightThumb={rightThumb}
                          setRightThumb={setRightThumb}
                          registeredThumb={registeredThumb}
                        />
                        <div className="flex flex-col justify-end">
                          <div className="flex justify-end mt-10 mr-10">
                            <Radio.Group size="large" value={txnType} onChange={(e) => setTxnType(e.target.value)}>
                              <Radio.Button value={'Entry'} className="text-2xl">
                                ENTRY
                              </Radio.Button>
                              <Radio.Button value={'Exit'} checked={true} className="text-2xl">
                                EXIT
                              </Radio.Button>
                            </Radio.Group>
                          </div>

                          <div className="flex justify-end mt-4">
                            <button
                              className=" px-3 py-2 text-white rounded mr-10 bg-green-700 border-3 border-green-600 m-4"
                              onClick={createTransaction}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col px-[2rem] items-center w-[42rem]  mt-10">
                      <img src={imgUrl} className="w-[20rem] h-[20rem]" />
                      <p className="my-5 text-3xl text-[red]">Resident Not Found!</p>
                      <button className="rounded my-2 p-2" style={{ backgroundColor: '#3b82f6', color: 'white' }}>
                        <Link to="/new" state={{ image: imgUrl }} className="py-3   font-medium rounded px-3">
                          Register New Person
                        </Link>
                      </button>
                      <button
                        onClick={() => {
                          setImgUrl(null);
                          setResidentData(null);
                        }}
                        className="border-2 p-2 rounded my-2"
                      >
                        Live Camera
                      </button>
                    </div>
                  )
                ) : null}
              </>
            )}
          </div>
        ) : (
          <></>
        )}

        {selectedTab == 'manual' ? <ManualSearch /> : null}
        {selectedTab == 'rtsp' ? <Rtsp /> : null}
      </div>
    </Spin>
  );
};

export default Home;
