import { useEffect, useState } from 'react';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { CaptureFinger, MatchFinger } from '../utils/biometric';
import { Spin, notification } from 'antd';
import verified from '../images/verified.svg';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const MatchFingerPrint = ({ type, rightThumb, setRightThumb, registeredThumb }) => {
  const [thumbSrc, setThumbSrc] = useState();
  const [thumbLoading, setThumbLoading] = useState(false);
  const [match, setMatch] = useState(false);
  const [fingerprintToCheck, setFingerPrintToCheck] = useState();

  var quality = 60; //(1 to 100) (recommanded minimum 55)
  var timeout = 10;

  const matchFinger = async () => {
    setThumbLoading(true);

    try {
      var res = await MatchFinger(quality, timeout, registeredThumb.iso);

      if (res.httpStaus) {
        if (res.data.Status) {
          notification.success({
            message: 'Fingerprint Verified!!'
          });
          setFingerPrintToCheck(res.fingerprintToCheck);
          setMatch(true);
        } else {
          if (res.data.ErrorCode != '0') {
            notification.error({
              message: 'Fingerprint does not match!!'
            });
            setMatch(false);
            // eslint-disable-next-line no-dupe-else-if
          } else if (res.data.ErrorCode != '0') {
            notification.error({
              message: 'Fingerprint not read properly!'
            });
            setMatch(false);
          }
        }
      } else {
        alert(res.err);
      }
      setThumbLoading(false);
    } catch (e) {
      alert(e);
      setThumbLoading(false);
    }
    return false;
  };

  return (
    <div className=" w-[23rem]   items-start  py-2">
      <div className="flex  justify-start items-center  ">
        <div
          className={`flex relative  flex-col w-[7rem] h-[9rem] border-2 items-center ${
            match ? 'border-green-500' : ''
          }`}
        >
          {registeredThumb?.bitmap ? (
            <img
              id="imgFinger"
              src={'data:image/bmp;base64,' + registeredThumb?.bitmap}
              className={`w-[7rem] h-[9rem] object-fill `}
            />
          ) : null}
          {match ? (
            <div className="absolute bottom-0 w-full bg-opacity-85 text-white bg-green-600	">Verified</div>
          ) : null}
        </div>
      </div>
      <div className="border-2 mt-2 w-[7rem] rounded ">
        <button onClick={() => matchFinger()} type="button" className="px-2 h-7 rounded my-1 w-[7rem]">
          {thumbLoading ? <HourglassTopIcon /> : 'Verify'}
        </button>
      </div>

      {/* <img
          id="imgFinger"
          src={"data:image/bmp;base64," + fingerprintToCheck}
          className={`h-[7rem] w-[5rem] object-fill `}
        /> */}

      {/* {fingerprintToCheck ? (
          match ? (
            <div className="flex items-center">
              <img src={verified} className="h-5 w-5 mr-2" />
              {type ? "Verified" : "Added"}
            </div>
          ) : (
            <div className="flex items-center w-[10rem]">
              "Fingerprint does not match!!"
            </div>
          )
        ) : null} */}
    </div>
  );
};

export default MatchFingerPrint;
