import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import VillageContext from '../context/VillageContext'; // Update the import path as per your project structure
import { Modal, Button } from 'antd';
import ViewDocumentModel from '../Modals/ViewDocumentModel';
import { Radio, notification } from 'antd';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { ThemeContext } from '../index';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MatchFingerPrint from '../components/MatchFingerPrint';
import { toast } from 'react-toastify';

const LiveDetectedEntry = ({ BACKEND_URL, imgUrl, residentData, frData, newUser, setNewUser, location }) => {
  const [remarks1, setRemarks1] = useState('');
  const [remarks2, setRemarks2] = useState('');
  const [remarks3, setRemarks3] = useState('');
  const [remarks4, setRemarks4] = useState('');
  const [categoryRemarks, setCategoryRemarks] = useState('');
  const [purposeOfVisit, setPurposeOfVisit] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleMakeModel, setVehicleMakeModel] = useState('');
  const [vehicleRemarks, setVehicleRemarks] = useState('');
  const [loading, setLoading] = useState(false);
  const [txnType, setTxnType] = useState('Entry');
  const [error, setError] = useState(false);
  const [category, setCategory] = useState('');
  const [isOutsider, setIsOutsider] = useState(false);
  const [rightThumb, setRightThumb] = useState();
  const [transactionMessage, setTransactionMessage] = useState('');

  const { village, node } = useContext(VillageContext);
  const { theme } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const { data } = frData;
  const [registeredThumb, setRegisteredThumb] = useState();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const currentVillage = village;

  const createTransaction = () => {
    const reqJson = {
      user_id: residentData?.user_id,
      village_id: currentVillage.split('-')[0],
      node_id: node.split('-')[0],
      entry_or_exit_at: moment(new Date()).tz('Asia/Kolkata'),
      imagebase64: imgUrl.slice(22),
      entry_or_exit: txnType === 'Entry' ? 'Entry' : 'Exit',
      category_remarks: categoryRemarks,
      category: newUser?.category,
      purpose_of_visit: purposeOfVisit,
      vehicle_type: vehicleType,
      vehicle_make_model: vehicleMakeModel,
      vehicle_remarks: vehicleRemarks,
      remarks1: remarks1,
      remarks2: remarks2,
      remarks3: remarks3,
      remarks4: remarks4
    };

    setLoading(true);
    fetch(`${BACKEND_URL}/createTransaction`, {
      method: 'POST',
      body: JSON.stringify(reqJson),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.statusCode === '201') {
          if (newUser?.category) {
            updateCategory();
          }
        }
        const type = txnType === 'Entry' ? 'Entry' : 'Exit';
        toast.success(`${type} marked`);
        setTransactionMessage(`${type} marked`);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        handleCancel();
      });
  };

  const updateCategory = () => {
    fetch(`${BACKEND_URL}/updateUserInfoV4/${residentData?.user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        category: newUser?.category
      })
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  let color = 'black'; // green
  if (data) {
    switch (data.category) {
      case 'Govt official':
        color = 'blue';
        break;
      case 'Defence Person':
        color = 'rgb(62, 142, 6)';
        break;
      case 'Terrorist':
        color = 'red';
        break;
      case 'Villager':
        color = '#a48b05';
        break;
      default:
        color = 'black';
    }
  }

  return frData.data ? (
    <Spin size="large" spinning={loading} className="w-1/2 ">
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal
        open={visible}
        onOk={handleCancel}
        onCancel={handleCancel}
        width="100%"
        className="bg-gray-700"
        style={{ top: 0, padding: 0 }}
      >
        <div>
          <div className="mx-[1rem] flex ">
            <div className="relative">
              <img src={frData.img} className="h-[20-rem] w-[20rem] object-contain bg-[blue]" />
              {data?.category == 'Anti-National' || data?.category == 'Anti National' ? (
                <div className="absolute bottom-0 w-full h-[3rem] text-xl text-white pt-2 bg-red-500 bg-opacity-50	 backdrop-blur-sm">
                  Anti National
                </div>
              ) : data?.wanted ? (
                <div className="absolute bottom-0 w-full h-[3rem] text-xl text-white pt-2 bg-red-500 bg-opacity-50	 backdrop-blur-sm">
                  Wanted
                </div>
              ) : null}
            </div>
            <div className="ml-5 text-[1rem] text-left w-1/2">
              <div className="font-thin  mb-[5px]  ">
                <span className="">First Name: </span>
                <span className="font-medium text-xl text-gray-700 dark:text-white">{data?.f_name}</span>
              </div>
              <div className="font-thin  mb-[5px]">
                <span>Last Name:</span>
                <span className="text-gray-700 text-xl font-medium dark:text-white"> {data?.l_name}</span>
              </div>
              <div className="flex">
                <div className="font-thin  mb-[5px]">
                  <span>Gender: </span>
                  <span className="font-medium text-xl">{data?.gender}</span>
                </div>
                <div className="font-thin  mb-[5px] ml-5">
                  <span>Age: </span>
                  <span className="font-medium text-xl">{data?.age ? data?.age : '-'}</span>
                </div>
              </div>

              <div className="font-thin  mb-[5px]">
                <span>DOB: </span>
                <span className="font-medium text-xl">
                  {data?.dob ? moment.utc(data?.dob).local().format('DD-MM-YYYY') : '-'}
                </span>
              </div>

              <div className="font-thin  mb-[5px]">
                <span>Contact: </span>
                <span className="font-medium text-xl">{data?.contact ? data?.contact : '-'}</span>
              </div>
              <div className="font-thin  mb-[5px]">
                <span>Aadhaar: </span>
                <span className="font-medium text-xl">{data?.aadhar ? data?.aadhar : '-'}</span>
              </div>
              <div className="font-thin  mb-[5px]">
                Address: <span className="font-medium text-xl">{data?.address ? data?.address : '-'}</span>
              </div>
            </div>
          </div>
          <div className="flex content-between text-[1rem] mt-5">
            <div className="ml-5 text-left  w-[20rem]">
              <div className="font-thin  mb-[5px]">
                <span>Village ID: </span>
                <span className="font-medium  text-xl">
                  {data?.village?.village_id ? data?.village?.village_id : '-'}
                </span>
              </div>

              <div className="font-thin  mb-[5px]">
                <span>Village Code: </span>
                <span className="font-medium text-xl">{data?.village?.v_code ? data?.village?.v_code : '-'}</span>
              </div>
              <div className="font-thin  mb-[5px]">
                <span>Village Name: </span>
                <span className={`font-medium ${data?.village.village_id === currentVillage ? '' : 'text-red-500'}`}>
                  {data?.village.v_name ? data?.village.v_name : '-'}
                </span>
              </div>

              <div className="w-full home-accordion">
                {data?.documentURL ? (
                  <div>
                    <div className="text-xl font-bold">Documents </div>
                    <div className="flex w-full">
                      {data?.documentURL?.map((url, i) => {
                        return (
                          <div className="relative  mr-2   w-[7rem]" key={i}>
                            <ViewDocumentModel
                              // image={"data:image/png;base64," + url}
                              image={url}
                              deleteDocument={() => {
                                notification.warning({
                                  message: 'You are not authorized to delete this document!!'
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>

              {/* category for person entry & exit ---end code */}

              {/* Adding 1 -4 remarks input field start here  */}
            </div>
            <div className="ml-5 text-left ">
              <div className="font-thin  mb-[5px]">
                <span>District: </span>
                <span className="font-medium text-xl">{data?.village.v_district ? data?.village.v_district : '-'}</span>
              </div>
              <div className="font-thin  mb-[5px]">
                <span>State: </span>
                <span className="font-medium text-xl">{data?.village.v_state ? data?.village.v_state : '-'}</span>
              </div>
            </div>
          </div>
          <div className="w-full home-accordion">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Remarks</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-3 pr-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks1">
                      Remarks 1
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="remarks1"
                      onChange={(e) => setRemarks1(e.target.value)}
                      value={location.state?.user?.remarks1 ? location.state?.user?.remarks1 : newUser.remarks1}
                      placeholder="Enter Remarks Here..."
                      disabled={location.state?.user}
                    />
                  </div>

                  <div className="mb-3 pr-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks2">
                      Remarks 2
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="remarks2"
                      onChange={(e) => setRemarks2(e.target.value)}
                      value={location.state?.user?.remarks2 ? location.state?.user?.remarks2 : newUser.remarks2}
                      placeholder="Enter Remarks Here..."
                      disabled={location.state?.user}
                    />
                  </div>

                  <div className="mb-3 pr-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks3">
                      Remarks 3
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="remarks3"
                      onChange={(e) => setRemarks3(e.target.value)}
                      value={location.state?.user?.remarks3 ? location.state?.user?.remarks3 : newUser.remarks3}
                      placeholder="Enter Remarks Here..."
                      disabled={location.state?.user}
                    />
                  </div>

                  <div className="mb-3 pr-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks4">
                      Remarks 4
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="remarks4"
                      onChange={(e) => setRemarks4(e.target.value)}
                      value={location.state?.user?.remarks4 ? location.state?.user?.remarks4 : newUser.remarks4}
                      placeholder="Enter Remarks Here..."
                      disabled={location.state?.user}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* category for vehicle entry & exit ---start code */}
          <div className="w-full home-accordion">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Entry/Exit Vehicle Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div
                  className="vehicle-container flex flex-wrap"
                  // style={{ display: "contents" }}
                >
                  <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Type
                    </label>
                    <input
                      className="appearance-none block w-full   border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                      id="grid-vehicle_type"
                      type="text"
                      placeholder="Please enter Vehicle type"
                      value={location.state?.user?.vehicle_type ? location.state?.user?.vehicle_type : vehicleType}
                      disabled={location.state?.user}
                      onChange={(e) => setVehicleType(e.target.value)}
                    />
                    {error && !newUser?.vehicle_type ? (
                      <p className="text-red-500 text-xs italic text-left mt-1">Please fill out this field.</p>
                    ) : null}
                  </div>
                  <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Number
                    </label>
                    <input
                      className="appearance-none block w-full   border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                      id="grid-vehicle_type"
                      type="text"
                      placeholder="Please enter Vehicle type"
                      value={vehicleNumber}
                      disabled={location.state?.user}
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                    {error && !newUser?.vehicle_type ? (
                      <p className="text-red-500 text-xs italic text-left mt-1">Please fill out this field.</p>
                    ) : null}
                  </div>
                  <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Make Model
                    </label>
                    <input
                      className="appearance-none block w-full   border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                      id="grid-vehicle_type"
                      type="text"
                      placeholder="Please Enter Vehicle Make Model"
                      value={
                        location.state?.user?.vehicle_make_model
                          ? location.state?.user?.vehicle_make_model
                          : newUser.vehicle_make_model
                      }
                      disabled={location.state?.user}
                      onChange={(e) => setVehicleMakeModel(e.target.value)}
                    />
                    {error && !newUser?.vehicle_make_model ? (
                      <p className="text-red-500 text-xs italic text-left mt-1">Please fill out this field.</p>
                    ) : null}
                  </div>
                  <div className="w-full  md:w-1/2 pr-3  md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Remarks
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="grid-vehicle_remarks"
                      type="text"
                      placeholder="Please enter Vehicle Remarks"
                      style={{
                        width: '104%',
                        height: '50%'
                      }}
                      value={
                        location.state?.user?.vehicle_remarks
                          ? location.state?.user?.vehicle_remarks
                          : newUser.vehicle_remarks
                      }
                      disabled={location.state?.user}
                      onChange={(e) => setVehicleRemarks(e.target.value)}
                    />
                    {error && !newUser?.vehicle_remarks ? (
                      <p className="text-red-500 text-xs italic text-left mt-1">Please fill out this field.</p>
                    ) : null}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* category for vehicle entry & exit ---end code */}

          <div className="w-full home-accordion">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Entry/Exit Visit Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="person-category-container flex flex-wrap mb-3 w-full pr-3 md:mb-3">
                  <div className="w-full">
                    <label className="block mt-2 uppercase text-left tracking-wide text-[1rem]" htmlFor="grid-state">
                      Person Category
                    </label>
                    <div className="relative">
                      <select
                        className={`block appearance-none bg-white w-full mb-2 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white ${
                          newUser?.category === 'Select Category for persons entering or exiting' || !newUser.category
                            ? 'text-gray-400'
                            : ''
                        }`}
                        id="grid-state"
                        disabled={location.state?.user}
                        onChange={(e) => {
                          setCategory(e.target.value);
                          setNewUser({
                            ...newUser,
                            category: e.target.value
                          });
                          setIsOutsider(e.target.value === 'V5042');
                        }}
                        value={
                          location.state?.user?.village?.category
                            ? location.state?.user.village?.category
                            : newUser.category
                        }
                      >
                        <option value={''}>Please select category of person</option>
                        <option value="Villager">Villager</option>
                        <option value="Govt official">Govt official</option>
                        <option value="Tourist">Tourist</option>
                        <option value="Defence Person">Defence Person</option>
                        <option value="Ex-Defence Person">Ex-Defence Person</option>
                        <option value="Anti-National">Anti-National</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full">
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="category_remarks"
                    >
                      Person Remarks
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="category_remarks"
                      onChange={(e) => setCategoryRemarks(e.target.value)}
                      value={
                        location.state?.user?.category_remarks
                          ? location.state?.user?.category_remarks
                          : newUser.category_remarks
                      }
                      placeholder="Enter Remarks Here..."
                      disabled={location.state?.user}
                    />
                  </div>
                  <div className="w-full" style={{ display: 'inline' }}>
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="purpose_of_visit"
                    >
                      Visit Purpose
                    </label>
                    <textarea
                      className="appearance-none block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="purpose_of_visit"
                      style={{
                        width: '100%',
                        height: '48%'
                      }}
                      onChange={(e) => setPurposeOfVisit(e.target.value)}
                      value={
                        location.state?.user?.purpose_of_visit
                          ? location.state?.user?.purpose_of_visit
                          : newUser.purpose_of_visit
                      }
                      placeholder="Enter Visit Purpose Here..."
                      disabled={location.state?.user}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="flex w-full justify-between border-t-2 ml-3 mt-3">
            <MatchFingerPrint
              type={'Verify'}
              rightThumb={rightThumb}
              setRightThumb={setRightThumb}
              registeredThumb={registeredThumb}
            />
            <div className="flex flex-col justify-end">
              <div className="flex justify-end mt-10 mr-10">
                <Radio.Group
                  disabled={transactionMessage}
                  size="large"
                  value={txnType}
                  onChange={(e) => setTxnType(e.target.value)}
                >
                  <Radio.Button value={'Entry'} className="text-2xl">
                    ENTRY
                  </Radio.Button>
                  <Radio.Button value={'Exit'} checked={true} className="text-2xl">
                    EXIT
                  </Radio.Button>
                </Radio.Group>
              </div>

              <div className="flex justify-end mt-4">
                <button
                  disabled={transactionMessage}
                  className=" px-3 py-2 text-white rounded mr-10 bg-green-700 border-3 border-green-600 m-4"
                  onClick={createTransaction}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <a
        href="#"
        className="text-left w-full flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 px-5 mb-5"
      >
        <img
          className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
          src={frData.img}
          style={{ height: '150px' }}
          alt=""
        />
        <div className="flex flex-col justify-between w-full p-4 leading-normal">
          <h6 className="mb-1 text-2xl font-bold tracking-tight text-gray-900" style={{ color: color }}>
            {data.f_name + ' ' + data.l_name}{' '}
            <span style={{ color: 'blue' }}>{transactionMessage && '(' + transactionMessage + ')'}</span>
          </h6>
          <p className="mb-1 font-normal text-gray-700">
            <b>Category- </b> {data.category}
          </p>
          <p className="mb-2 font-normal text-gray-700">
            <b>Village: </b> {data?.village.v_name ? data?.village.v_name : '-'} <b>District:</b>{' '}
            {data?.village.v_district ? data?.village.v_district : '-'}
          </p>

          <div className="flex flex-row justify-between">
            <div className="flex justify-end mr-10">
              <Radio.Group
                disabled={transactionMessage}
                size="large"
                value={txnType}
                onChange={(e) => setTxnType(e.target.value)}
              >
                <Radio.Button value={'Entry'} className="text-2xl">
                  ENTRY
                </Radio.Button>
                <Radio.Button value={'Exit'} checked={true} className="text-2xl">
                  EXIT
                </Radio.Button>
              </Radio.Group>
            </div>
            <button className="mx-2" disabled={transactionMessage} onClick={showModal}>
              <EditIcon />
            </button>
            <button
              disabled={transactionMessage}
              className=" px-3 py-2 text-white rounded bg-green-700 border-3 border-green-600"
              onClick={createTransaction}
            >
              Submit
            </button>
            {/* <div className="flex justify-end mt-4">
                          <button
                              disabled={transactionMessage}
                              onClick={showModal}
                          >
                              <EditIcon />
                          </button>
                      </div>

                      <div className="flex  justify-end mt-4">
                          <button
                              disabled={transactionMessage}
                              className=" px-3 py-2 text-white rounded mr-10 bg-green-700 border-3 border-green-600 m-4"
                              onClick={createTransaction}
                          >
                              Submit
                          </button>
                      </div> */}
          </div>
        </div>
      </a>
    </Spin>
  ) : (
    <Link
      to="/new"
      state={{ image: frData.img }}
      className="text-left w-full flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 px-5 mb-5"
    >
      <img
        className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg my-5"
        src={frData.img}
        alt=""
        style={{ height: '100px' }}
      />
      <div className="flex flex-col justify-between w-full p-4 leading-normal">
        <h6 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Resident Not Found!</h6>
        <p>Please Register!</p>
      </div>
    </Link>
  );
};

export default LiveDetectedEntry;
