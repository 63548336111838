import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Select, Spin } from 'antd';

const Villages = () => {
  const [villages, setVillages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVillages();
  }, []);

  const columns = useMemo(() => [
    {
      accessorKey: 'village_id', //access nested data with dot notation
      header: 'Id',
      size: 150
    },
    {
      accessorKey: 'v_name', //access nested data with dot notation
      header: 'Name',
      size: 150
    },
    {
      accessorKey: 'v_code', //access nested data with dot notation
      header: 'Village Code',
      size: 150
    },

    {
      accessorKey: 'v_lat', //access nested data with dot notation
      header: 'Latitude',
      size: 150
    },
    {
      accessorKey: 'v_long', //access nested data with dot notation
      header: 'Longitude',
      size: 150
    },
    {
      accessorKey: 'v_district', //access nested data with dot notation
      header: 'District',
      size: 150
    },
    {
      accessorKey: 'v_state', //access nested data with dot notation
      header: 'State',
      size: 150
    },
    {
      accessorKey: 'v_pincode', //access nested data with dot notation
      header: 'Pincode',
      size: 150
    }
  ]);

  const table = useMaterialReactTable({
    columns,
    data: villages,
    initialState: {
      density: 'compact',
      columnVisibility: {
        v_pincode: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false

    // renderTopToolbarCustomActions: () => (
    //   <div className="flex items-end">
    //     <div className="flex flex-col item">
    //       <div className="text-left text-[1rem] text-gray-500">
    //         Select Date{" "}
    //       </div>
    //       <RangePicker
    //         format={"DD/MM/YYYY"}
    //         defaultValue={[
    //           dayjs(moment(new Date()).add(-1, "month").format("YYYY-MM-DD")),
    //           dayjs(moment(new Date()).format("YYYY-MM-DD")),
    //         ]}
    //         onChange={handleDateChange}
    //       />
    //     </div>{" "}
    //     <button
    //       className=" px-3 py-1 rounded ml-3 h-[2rem] bg-green-500 text-white  "
    //       onClick={getTransactions}
    //     >
    //       Submit
    //     </button>
    //   </div>
    // ),
  });

  const getVillages = () => {
    setLoading(true);
    fetch('https://vvts-iccc-backend-poc.futuretech-3i.com/getVillageDetails')
      .then(async (res) => {
        const data = await res.json();
        console.log(data);
        data.result.pop();
        setVillages(data.result);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const handleNextPage = () => {
    if (currentPage == Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage == 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="mx-3 mt-3">
        <div className="flex justify-between    my-2   py-4">
          <span className="text-3xl font-bold text-left text-blue-500">Villages</span>
        </div>
        <MaterialReactTable table={table} />
        <div className="mt-3 mb-5 flex justify-between">
          <div>
            <span>Rows Per Page:</span>
            <Select
              className="mx-2"
              options={[
                {
                  value: '5',
                  label: '5'
                },
                {
                  value: '10',
                  label: '10'
                },
                {
                  value: '20',
                  label: '20'
                }
              ]}
              onChange={(e) => {
                setRowsPerPage(e);
                setCurrentPage(1);
              }}
              value={rowsPerPage}
            />
          </div>
          <div className="flex items-center ">
            <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
            <div>
              {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
            </div>
            <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Villages;
