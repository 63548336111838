import moment from 'moment';
import { useState } from 'react';
import NoImage from '../images/no-image.svg';

const { Modal } = require('antd');

const ViewImageModal = ({ image, metaData }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const footer = (
    <div className="flex justify-between py-2 pb-0 ">
      <div className="text-left text-[15px] text-gray-600">
        {metaData?.entry_or_exit_at ? (
          <>
            <div className="text-left">
              <span> Captured on: </span>{' '}
              <span>{moment.utc(metaData?.entry_or_exit_at).local().format('DD,MMMM, YYYY  h:mm:ss A')}</span>
            </div>
          </>
        ) : null}
      </div>
      <button className="px-2 py-1 border-2 rounded justify-self-end" onClick={handleClose}>
        Close
      </button>
    </div>
  );

  return (
    <div>
      <Modal open={open} onCancel={handleClose} footer={footer}>
        <div className="flex justify-center">
          <div>
            <div className="text-xl font-bold text-gray-600">
              {metaData?.userInfo?.f_name + ' ' + metaData?.userInfo?.l_name + ' '}
            </div>

            <img src={image} className="h-[20rem] w-[30rem] object-cover" alt={metaData?.userInfo?.f_name} />
          </div>
        </div>
      </Modal>
      <button>
        {image ? (
          <img
            className="w-[3rem] h-[3rem] object-cover"
            onClick={() => {
              if (image) setOpen(true);
            }}
            src={image}
            alt={metaData?.userInfo?.f_name}
          />
        ) : (
          <img className="w-[3rem] h-[3rem] object-cover" src={NoImage} alt="No Photo" />
        )}
      </button>
    </div>
  );
};

export default ViewImageModal;
