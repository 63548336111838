import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import { DatePicker, Select, Spin } from 'antd';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Modal } from 'antd';
import ViewImageModal from '../Modals/ViewImageModal';
import VillageContext from '../context/VillageContext';
import { BACKEND_URL, VILLAGE_NAME } from '../constants';
import ClearIcon from '@mui/icons-material/Clear';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
  filename: moment().format('YYYYMMDD') + 'Mandi'
});

const Transmissions = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const [totalRecords, setTotalRecords] = useState();
  const [showExportModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();

  const [fromDate, setFromDate] = useState(
    moment.tz(dayjs(), 'Asia/Kolkata').add(-1, 'days').add(-18.5, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );
  const [endDate, setEndDate] = useState(
    moment
      .tz(dayjs(), 'Asia/Kolkata')
      .add(1, 'day')
      .add(5.5, 'hours')
      .add(-1, 'minutes')
      .add(59, 'seconds')
      .add(999, 'milliseconds')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  );

  const { village, setVillage, node, setNode } = useContext(VillageContext);

  useEffect(() => {
    if (!!village) {
      getTransactions();
    }
  }, [currentPage, rowsPerPage, village]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'userInfo.f_name', //access nested data with dot notation
        header: 'First Name',
        size: 150
      },
      {
        accessorKey: 'userInfo.l_name',
        header: 'Last Name',
        size: 150
      },
      {
        accessorKey: 'userInfo.gender',
        header: 'Gender',
        size: 150
      },

      {
        accessorKey: 'userInfo.village.v_name', //normal accessorKey
        header: 'Home Village',
        size: 200
      },
      {
        accessorKey: 'village.v_name', //normal accessorKey
        header: 'Entry / Exit Village',
        size: 200
      },
      {
        accessorKey: 'nodeDetails.n_name',
        header: 'Entry / Exit Node',
        size: 150
      },
      {
        accessorKey: 'entry_or_exit_at',
        header: 'Last Recorded Entry / Exit',
        size: 150,

        accessorFn: (row) => {
          var gmtDateTime = moment.utc(row.entry_or_exit_at);
          var local = gmtDateTime.local().format('DD-MM-YYYY h:mm:ss A');
          return local;
        }
      },
      {
        accessorKey: 'entry_or_exit',
        header: 'Entry / Exit',
        size: 150
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 150
      },
      {
        accessorKey: 'imagebase64',
        header: 'Image',
        size: 150,
        accessorFn: (row) => {
          return <ViewImageModal image={row.imagebase64} metaData={row} />;
        }
      },
      {
        accessorKey: 'category_remarks',
        header: 'Category Remarks',
        size: 150
      },
      {
        accessorKey: 'purpose_of_visit',
        header: 'Visit Purpose',
        size: 150
      },
      {
        accessorKey: 'vehicle_type',
        header: 'Vehicle Type',
        size: 150
      },
      {
        accessorKey: 'vehicle_make_model',
        header: 'Vehicle Model',
        size: 150
      },
      {
        accessorKey: 'remarks1',
        header: 'Remarks 1',
        size: 150
      },
      {
        accessorKey: 'remarks2',
        header: 'Remarks 2',
        size: 150
      },
      {
        accessorKey: 'remarks3',
        header: 'Remarks 3',
        size: 150
      },
      {
        accessorKey: 'remarks4',
        header: 'Remarks 4',
        size: 150
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    initialState: {
      density: 'compact',
      columnVisibility: {
        'village.v_name': false,
        'nodeDetails.n_name': false,
        category_remarks: false,
        purpose_of_visit: false,
        vehicle_type: false,
        vehicle_make_model: false,
        remarks1: false,
        remarks2: false,
        remarks3: false,
        remarks4: false
      }
    },
    enableHiding: false,
    enableDensityToggle: false,
    data: transactionData,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false,

    renderTopToolbarCustomActions: () => (
      <div className="flex items-end">
        <div className="flex flex-col item ">
          <div className="text-left text-[1rem] text-gray-500  ">Select Date </div>
          <RangePicker
            className="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            format={'DD/MM/YYYY'}
            defaultValue={[
              dayjs(moment(new Date()).add(-2, 'days').format('YYYY-MM-DD')),
              dayjs(moment(new Date()).format('YYYY-MM-DD'))
            ]}
            onChange={handleDateChange}
          />
        </div>{' '}
        <button className=" px-3 py-1 rounded ml-3 h-[2rem] bg-green-500 text-white" onClick={getTransactions}>
          Submit
        </button>
      </div>
    )
  });

  const { RangePicker } = DatePicker;

  const handleDateChange = (e, string) => {
    if (e) {
      setFromDate(
        moment.tz(e[0].toDate(), 'Asia/Kolkata').add(5.5, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
      setEndDate(
        moment
          .tz(e[1].toDate(), 'Asia/Kolkata')
          .add(1, 'day')
          .add(5.5, 'hours')
          .add(-1, 'minutes')
          .add(59, 'seconds')
          .add(999, 'milliseconds')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
    } else {
      setFromDate(moment.tz(dayjs(), 'Asia/Kolkata').add(5.5, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
      setEndDate(
        moment
          .tz(dayjs(), 'Asia/Kolkata')
          .add(1, 'day')
          .add(5.5, 'hours')
          .add(-1, 'minutes')
          .add(59, 'seconds')
          .add(999, 'milliseconds')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
    }
  };

  const getTransactions = () => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getTransaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        from_date: fromDate,
        to_date: endDate,
        limit: parseInt(rowsPerPage),
        page: currentPage,
        q: {
          village_id: [village.split('-')[0]]
        }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setTransactionData(data?.result);
        setTotalRecords(data?.totalRecords);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const handleExportData = () => {
    setLoading(true);
    setShowConfirmModal(false);
    fetch(`${BACKEND_URL}/exportall`, {
      method: 'GET',
      headers: {
        'Content-Type': 'blob'
      }
      // body: JSON.stringify({
      //   from_date: fromDate,
      //   to_date: endDate,
      //   // limit: parseInt(rowsPerPage),
      //   // page: currentPage,
      //   q: {
      //     village_id: [village.split("-")[0]],
      //   },
      // }),
    })
      .then(async (res) => {
        const blob = await new Blob([await res.blob()], {
          type: 'application/json; charset=utf-8'
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = moment().format('YYYYMMDD') + VILLAGE_NAME + '.json';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  };

  const handleFileImport = (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files) {
      setFile(e.target.files[0]);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      fetch(`${BACKEND_URL}/importall`, {
        method: 'POST',
        body: formData
      })
        .then(() => {
          getTransactions();
          // openNotification(file);
          alert('Imported succesfully');
          setFile();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleNextPage = () => {
    if (currentPage == Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage == 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin size="large" spinning={loading} className="mt-[9rem]">
      <div className="w-[97%] h-auto  table-container mx-[auto] overflow-auto ">
        <Modal
          title="Are you sure?"
          open={showExportModal}
          footer={
            <div className="">
              <button onClick={() => setShowConfirmModal(false)} className="border-2 px-3 py-1 rounded mr-2">
                Cancel
              </button>
              <button onClick={handleExportData} className=" px-3 py-1 rounded mr-2 bg-blue-700 text-white">
                Ok
              </button>
            </div>
          }
        >
          <p>Do you want to download the CSV?</p>
        </Modal>
        <div className="flex justify-between    my-2   py-4">
          <span className="text-3xl font-bold text-left text-blue-500">Entry / Exit Logs</span>
        </div>
        <MaterialReactTable table={table} />
        <div className="mt-3 mb-5 flex justify-between">
          <div>
            <span>Rows Per Page:</span>
            <Select
              className="mx-2"
              options={[
                {
                  value: '5',
                  label: '5'
                },
                {
                  value: '10',
                  label: '10'
                },
                {
                  value: '20',
                  label: '20'
                }
              ]}
              onChange={(e) => {
                setRowsPerPage(e);
                setCurrentPage(1);
              }}
              value={rowsPerPage}
            />
          </div>
          <div className="flex items-center ">
            <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
            <div>
              {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
            </div>
            <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Transmissions;
