import { Checkbox, DatePicker, Radio, Select, Spin, notification } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';
import Webcam from 'react-webcam';
import EditFingerPrint from '../components/EditFingerPrint';
import VillageContext from '../context/VillageContext';
import '../App.css';
import { Add, Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DependantFields from '../components/DependantFields';
import Rtsp from '../components/Rtsp';
import DocumentsUpload from '../components/DocumentsUpload';
import ViewDocumentModel from '../Modals/ViewDocumentModel';
import { BACKEND_URL, FRS_URL, currentFr, PYTHON_FRS_URL } from '../constants';
import { ThemeContext } from '../index';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';

const url = 'http://localhost:4000/api/ocr';
const EditResidentDetails = () => {
  const [villages, setVillages] = useState([]);
  const [error, setError] = useState(false);
  const [isOutsider, setIsOutsider] = useState(false);
  const [txnType, setTxnType] = useState('Entry');
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [remarks1, setRemarks1] = useState('');
  const [remarks2, setRemarks2] = useState('');
  const [remarks3, setRemarks3] = useState('');
  const [remarks4, setRemarks4] = useState('');
  const [category, setCategory] = useState('');
  const [categoryRemarks, setCategoryRemarks] = useState('');
  const [purposeOfVisit, setPurposeOfVisit] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleMakeModel, setVehicleMakeModel] = useState('');
  const [vehicleRemarks, setVehicleRemarks] = useState('');
  const [dependants, setDependants] = useState([]);
  const [nonDependants, setNonDependants] = useState([]);
  const [profession, setProfession] = useState('');
  const [professionDescription, setProfessionDescription] = useState('');
  const [emailId, setEmailId] = useState('');
  const [socialMedia, setSocialMedia] = useState('');
  const [educationalInfo, setEducationalInfo] = useState('');
  const [qualification, setQualification] = useState('');
  const [school, setSchool] = useState('');
  const [selecetedCamera, setSelectedCamera] = useState('web');
  const [alias, setAlias] = useState('');
  const [religion, setReligion] = useState('');
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [consentGiven, setConsentGiven] = useState(true);
  const [documentImages, setDocumentImages] = useState([]);
  const [cameraToggle, setCameraToggle] = useState('person');
  const [registeredThumb, setRegisteredThumb] = useState();
  const [rightThumb, setRightThumb] = useState();
  const [verifyFace, setVerifyFace] = useState(true);

  const { village, setVillage, node, setNode } = useContext(VillageContext);

  const webCamRef = useRef();

  const videoConstraints = {
    // width: 540,
    outerHeight: 1000,
    facingMode: 'environment'
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState({ ...location?.state?.user });

  const currentVillage = village.split('-')[0];
  const { theme } = useContext(ThemeContext);
  const cameraOptions = [
    {
      label: (
        <span className="text-[1rem]" style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }}>
          Web Cam
        </span>
      ),
      value: 'web'
    },
    {
      label: (
        <span className="text-[1rem]" style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }}>
          IP Camera
        </span>
      ),
      value: 'ipCam'
    },
    {
      label: (
        <span className="text-[1rem]" style={{ color: theme.palette.mode === 'dark' ? 'white' : '' }}>
          Body Camera
        </span>
      ),
      value: 'bodyCam'
    }
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    colot: 'blue',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  });

  const handleDocImageUpload = (event) => {
    const file = event?.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (newUser?.documents?.length < 2) {
          setNewUser((prev) => ({
            ...prev,
            documents: [...prev.documents, reader.result]
          }));
          setDocumentImages((prev) => [...prev, reader.result]);
        } else {
          notification.warning({ message: 'Only 2 Documents required' });
        }

        sendImageFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFaceImageUpload = (event) => {
    const file = event?.target?.files[0];

    if (file) {
      const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;

      const reader = new FileReader();
      reader.onloadend = () => {
        if (location?.state?.user) {
          setImages([reader.result]);
          setNewUser((prev) => ({
            ...prev,
            imagebase64: `data:image/png;base64,${reader.result.toString('base64')}`.slice(22)
          }));
        } else if (images?.length < numberOfImagesRequired) {
          setImages((prev) => [...prev, reader.result]);
        } else {
          notification.warning({
            message: `Only ${numberOfImagesRequired} Photos required`
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    getVillageList();
    if (location?.state?.image) {
      setImages([...images, location?.state?.image]);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.user) {
      console.log(location?.state?.user, ':kkkk');
      console.log(location?.state?.user);
      setNewUser(location?.state?.user);
      getRelatives(location?.state?.user?.user_id);
      getRegisteredFingerPrint(location?.state?.user?.user_id);
      setImages([...images, location?.state?.user?.image]);
      if (location?.state?.user?.village_id) {
        setIsOutsider(location?.state?.user?.village_id == 'V5042');
      }
    }
  }, []);

  const urlToBlob = async (imageUrl, name) => {
    try {
      const response = await fetch(imageUrl);

      const blob = await response.blob();
      const namedBlob = await new Blob([blob], {
        type: 'image/png'
      });

      console.log(await blobToImageFile(namedBlob, name));

      return await blobToImageFile(namedBlob, name);
    } catch (error) {
      console.error('Error converting image URL to blob:', error);
      throw error;
    }
  };

  const base64ToBlob = async (base64, fileName) => {
    const binaryString = window.atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return await blobToImageFile(await new Blob([arrayBuffer], { type: 'image/png' }), fileName);
  };

  const getVillageList = () => {
    fetch(`${BACKEND_URL}/getVillageDetails`)
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((village, i) => {
          return {
            label: village.v_name,
            value: village.village_id
          };
        });
        setVillages(options);
      })
      .catch((err) => console.log(err));
  };
  const checkFace = async (base64Image) => {
    try {
      const response = await fetch(`${PYTHON_FRS_URL}/check_face`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          base64_image: base64Image
        })
      });

      if (!response.ok) {
        if (response.status === 400) {
          const data = await response.json();
          throw new Error(data.message);
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error('Could Not Store Face');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const base64Image = images[0]
      ? images[0]
      : newUser?.imagebase64?.includes('data')
        ? newUser?.imagebase64
        : 'data:image/png;base64,' + newUser?.imagebase64;

    try {
      const result = verifyFace ? await checkFace(base64Image) : { status: 'success' };

      if (result.status === 'success') {
        // Proceed with the form submission
        if (consentGiven) {
          if (!location.state?.user) {
            // if (images.length < 5) {
            //   notification.warning({
            //     message: "Please capture atleast 5 Photos. ",
            //   });
            //   return;
            // }

            if (
              !newUser.f_name ||
              !newUser.l_name
              // (images.length == 0 && !location.state?.image)
            ) {
              setError(true);
              return;
            } else {
              setError(false);
            }
          }

          if (!newUser.f_name || !newUser.l_name) {
            setError(true);
            return;
          } else {
            setError(false);
          }
          setLoading(true);

          const data = {
            ...newUser,
            age: parseInt(newUser?.age),
            imagebase64: images[0]?.slice(22),
            villageEntryExits: {
              create: [
                {
                  village_id: currentVillage.split('-')[0],
                  node_id: node.split('-')[0],
                  entry_or_exit_at: new Date().toISOString()
                }
              ]
            }
          };

          fetch(`${BACKEND_URL}/updateUserInfo/${location?.state?.user?.user_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              if (data.statusCode == 200) {
                notification.success({
                  message: 'User Updated'
                });
                registerNewPersonV2(newUser?.user_id);
                addRelatives(newUser?.user_id);
                registerThumb(newUser?.user_id);
                uploadResidentDocuments(newUser?.user_id, newUser?.id);
              }
              return data.statusCode;
            })
            .then((statusCode) => {
              if (statusCode == 200) {
                navigate('/people');
              }
            })
            .catch((err) => console.log(err));
        } else {
          notification.warning({
            message: 'Please get consent from the concerned person!'
          });
        }
      }
    } catch (error) {
      // Display error message from checkFace
      toast.error(error.message);
    }
  };

  const captureDoc = () => {
    const src = webCamRef.current.getScreenshot();

    console.log('documents');
    console.log(src);

    if (newUser?.documents?.length < 2) {
      setNewUser({
        ...newUser,
        documents: [...newUser.documents, src]
      });
      setDocumentImages([...documentImages, src]);
    } else {
      notification.warning({ message: 'Only 2 Documents required' });
    }

    sendImageFile(src);
  };

  const captureFace = () => {
    const src = webCamRef.current.getScreenshot();
    const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;
    if (location?.state?.user) {
      setImages([src]);
      setNewUser({ ...newUser, imagebase64: src.slice(22) });
    } else if (images?.length < numberOfImagesRequired) {
      setImages([...images, src]);
    } else {
      notification.warning({
        message: `Only ${numberOfImagesRequired} Photos required`
      });
    }
  };

  const captureImage = () => {
    const src = webCamRef.current.getScreenshot();
    // if (location?.state?.user) {
    //   setImages([src]);
    // } else {
    if (cameraToggle == 'person') {
      const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;
      if (location?.state?.user) {
        setImages([src]);
        setNewUser({ ...newUser, imagebase64: src.slice(22) });
      } else if (images?.length < numberOfImagesRequired) {
        setImages([...images, src]);
      } else {
        notification.warning({
          message: `Only ${numberOfImagesRequired} Photos required`
        });
      }
    } else {
      console.log('documents');
      console.log(src);

      if (newUser?.documents?.length < 2) {
        setNewUser({
          ...newUser,
          documents: [...newUser.documents, src]
        });
        setDocumentImages([...documentImages, src]);
      } else {
        notification.warning({ message: 'Only 2 Documents required' });
      }

      sendImageFile(src);
    }
    // }
  };

  const captureImageFromIp = (docImgUrl, faceImgUrl) => {
    const src = faceImgUrl;
    if (cameraToggle == 'person') {
      const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;
      if (location?.state?.user) {
        setImages([src]);
        setNewUser({ ...newUser, imagebase64: src.slice(22) });
      } else if (images?.length < numberOfImagesRequired) {
        setImages([...images, src]);
      } else {
        notification.warning({
          message: `Only ${numberOfImagesRequired} Photos required`
        });
      }
    } else {
      if (newUser?.documents?.length < 2) {
        setNewUser({
          ...newUser,
          documents: [...newUser.documents, docImgUrl]
        });
        // setDocumentImages([...documentImages, src]);
      } else {
        notification.warning({ message: 'Only 2 Documents required' });
      }
    }
  };

  const deleteImage = (index) => {
    const imgs = [...images];
    imgs.splice(index, 1);
    setImages(imgs);
  };

  const deleteDocument = (index) => {
    const imgs = [...newUser.documents];
    imgs.splice(index, 1);
    setNewUser({ ...newUser, documents: imgs });
  };

  const registerNewPerson = (id) => {
    fetch(`${FRS_URL}/create_face_encodings`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: newUser.f_name,
        images: images.map((elem) => elem.slice(22)),
        id
      })
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const registerNewPersonV2 = (id) => {
    fetch(`${PYTHON_FRS_URL}/store_face`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: newUser.f_name,
        base64_image: images[0],
        user_id: id
      })
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            toast.error('No face found in the image.');
          }
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Internal server error.');
      });
  };

  const removeEmptyObject = (obj) => {
    return obj.filter((elem) => !(Object.keys(elem).length === 0));
  };

  const addRelatives = (userId) => {
    const reqJson = {
      relationship_json: {
        dependants: removeEmptyObject(dependants),
        Nondependants: removeEmptyObject(nonDependants)
      }
    };

    fetch(`${BACKEND_URL}/updateRelationship/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqJson)
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const addNewDependant = () => {
    setDependants([...dependants, {}]);
  };

  const addNewNonDependant = () => {
    setNonDependants([...nonDependants, {}]);
  };

  const getRelatives = (uid) => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getRelationship`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          user_id: uid
        },
        limit: 1000,
        page: 1
      })
    })
      .then(async (res) => {
        const data = await res.json();
        return data.result[0] ? data.result[0] : null;
      })
      .then((relatives) => {
        if (relatives?.relationship_json?.dependants && relatives?.relationship_json?.dependants.length > 0) {
          setDependants(relatives?.relationship_json?.dependants);
        } else {
          setDependants([{}]);
        }

        if (relatives?.relationship_json?.Nondependants && relatives?.relationship_json?.Nondependants.length > 0) {
          setNonDependants(relatives?.relationship_json?.Nondependants);
        } else {
          setNonDependants([{}]);
        }
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  async function blobToImageFile(blob, fileName) {
    return await new File([blob], fileName, { type: 'image/png' });
  }

  const getRegisteredFingerPrint = (uid) => {
    const query = {
      q: {
        user_id: uid
      }
    };
    fetch(`${BACKEND_URL}/getFingerprint`, {
      method: 'POST',
      body: JSON.stringify(query),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => setRegisteredThumb(data.result[0]))
      .catch((err) => console.log(err));
  };

  const getRegisteredAddress = (uid) => {};

  async function sendImageFile(imageUri) {
    try {
      // Fetch the image as a Blob
      const response = await axios.get(imageUri, { responseType: 'blob' });
      const blob = response.data;

      // Create a FormData object and append the fcle
      const formData = new FormData();
      formData.append('file', blob, 'image.png'); // 'file' is the key expected by the server, and 'image.png' is the filename

      // Send the POST request with the FormData
      const postResponse = await axios.post(url, formData);

      // Handle the response from the server
      console.log('Upload successful', postResponse.data);
    } catch (error) {
      console.error('Error sending image:', error);
    }
  }

  const uploadResidentDocuments = async (userId, id) => {
    if (newUser?.documents && !newUser?.documents[0]) return;
    const formData = new FormData();

    for (let i = 0; i < newUser?.documents?.length; i++) {
      if (newUser?.documents?.length > 0) {
        formData.append(
          `files`,
          await base64ToBlob(
            newUser?.documents[i]?.includes('data')
              ? newUser?.documents[i]
              : 'data:image/png;base64,' + newUser?.documents[i],
            `${userId}_${id}_document_${i}.png`
          )
        );
      } else {
        formData.append(`files`, await urlToBlob(newUser?.documents[i], `${userId}_${id}_document_${i}.png`));
      }
    }

    formData.append('user_id', userId);
    formData.append('id', id);

    fetch(`${BACKEND_URL}/fileupload`, {
      method: 'POST',
      body: formData
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const updateThumb = (uid) => {
    fetch(`${BACKEND_URL}/updateFingerprint/${uid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...rightThumb })
    });
  };

  const registerThumb = (uid) => {
    fetch(`${BACKEND_URL}/createFingerprint`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: location?.state?.user?.user_id,
        ...rightThumb
      })
    });
  };

  const filterVillageOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleVillageChange = (value) => {
    setNewUser({
      ...newUser,
      village_id: value
    });
    setIsOutsider(value == 'V5042');
  };

  return (
    <Spin className="mt-[9rem] bg-black" size="large" spinning={false}>
      <div className="px-6 mt-2 ">
        <div className="flex justify-between py-4 my-3 border-b-2 ">
          <span className="text-3xl font-bold text-left text-blue-500">Edit Resident Details</span>
        </div>
        <form className="w-full max-w-full" onSubmit={handleSubmit}>
          <div className="flex justify-between">
            <p className="text-xl font-bold text-left">Personal Details</p>
            <Radio.Group
              options={cameraOptions}
              onChange={(e) => setSelectedCamera(e.target.value)}
              value={selecetedCamera}
            />
          </div>
          <div className="flex flex-wrap my-3 ">
            <div className="flex flex-wrap w-3/4">
              <div className="w-full pr-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  First Name *
                </label>
                {console.log(location?.state?.user)}
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-first-name"
                  type="text"
                  placeholder="Please enter First Name."
                  value={newUser?.f_name ? newUser?.f_name : ''}
                  // disabled={location.state?.user}
                  onChange={(e) => setNewUser({ ...newUser, f_name: e.target.value })}
                />
                {error && !newUser?.f_name ? (
                  <p className="mt-1 text-xs italic text-left text-red-500">Please fill out this field.</p>
                ) : null}
              </div>

              <div className="w-full pr-3 md:w-1/3">
                <label className="block text-left mt-2  uppercase tracking-wide  text-[1rem] " htmlFor="grid-last-name">
                  Last Name *
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Please Enter Last Name."
                  value={newUser?.l_name ? newUser?.l_name : ''}
                  onChange={(e) => setNewUser({ ...newUser, l_name: e.target.value })}
                  // disabled={location.state?.user}
                />
                {error && !newUser?.l_name ? (
                  <p className="mt-1 text-xs italic text-left text-red-500">Please fill out this field.</p>
                ) : null}
              </div>
              <div className="w-full pr-3 md:w-1/3">
                <label className="block text-left mt-2  uppercase tracking-wide  text-[1rem] " htmlFor="grid-last-name">
                  Alias
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Please Enter Alias."
                  value={newUser?.alias_name ? newUser?.alias_name : ''}
                  onChange={(e) => setNewUser({ ...newUser, alias_name: e.target.value })}
                  // disabled={location.state?.user}
                />
              </div>
              <div className="w-full py-3 pr-3 md:w-1/3 md:mb-0 ">
                <label className="block uppercase text-left tracking-wide    text-[1rem] " htmlFor="grid-state">
                  Gender
                </label>
                <div className="relative ">
                  <select
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      newUser?.gender == 'Please select gender.' || !newUser.gender ? 'text-gray-400' : ''
                    }`}
                    id="grid-state"
                    onChange={(e) => setNewUser({ ...newUser, gender: e.target.value })}
                    value={newUser?.gender ? newUser?.gender : ''}
                    // disabled={location.state?.user}
                  >
                    {console.log(newUser, 'new')}
                    <option className="text-gray-400">Please select your gender.</option>
                    <option className="text-gray-800">Male</option>
                    <option className="text-gray-800">Female</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Contact
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-first-name"
                  type="text"
                  onChange={(e) => setNewUser({ ...newUser, contact: e.target.value })}
                  value={newUser?.contact}
                  placeholder="Please enter contact number."
                  // disabled={location.state?.user}
                />
              </div>
              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Age
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-first-name"
                  type="number"
                  placeholder="Please enter age."
                  onChange={(e) => {
                    setNewUser({ ...newUser, age: e.target.value });
                  }}
                  value={newUser?.age ? newUser?.age : ''}
                  // disabled={location.state?.user}
                />
              </div>
              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Date of Birth
                </label>
                <DatePicker
                  // disabled={location.state?.user}
                  defaultValue={location.state?.user?.dob ? dayjs(location.state?.user?.dob) : dayjs(newUser.dob)}
                  format={'MM-DD-YYYY'}
                  onChange={(e) => {
                    if (e) {
                      setNewUser({
                        ...newUser,
                        dob: new Date(e.toDate()).toISOString()
                      });
                    }
                  }}
                  // allowClear={false}
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full h-[45px]"
                />
              </div>

              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Religion
                </label>
                <input
                  // disabled={location.state?.user}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-first-name"
                  type="text"
                  placeholder="Please enter Religion"
                  onChange={(e) => setNewUser({ ...newUser, religion: e.target.value })}
                  value={newUser?.religion ? newUser?.religion : ''}
                />
              </div>

              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  ID Proof / Aadhaar
                </label>
                <input
                  // disabled={location.state?.user}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="grid-first-name"
                  type="text"
                  placeholder="Please enter aadhaar number."
                  onChange={(e) => setNewUser({ ...newUser, aadhar: e.target.value })}
                  value={newUser?.aadhar ? newUser?.aadhar : ''}
                />
              </div>

              <div className="w-full py-3 pr-3 md:w-1/3 md:mb-0 ">
                <label className="block uppercase text-left tracking-wide    text-[1rem] " htmlFor="grid-state">
                  Person Category
                </label>
                <div className="relative ">
                  <select
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      newUser?.gender == 'Please select gender.' || !newUser.category ? 'text-gray-400' : ''
                    }`}
                    id="grid-state"
                    onChange={(e) => setNewUser({ ...newUser, category: e.target.value })}
                    value={newUser?.category ? newUser?.category : ''}
                  >
                    {/* // {console.log(newUser, 'new')} */}
                    <option value={''}>Please select category of person</option>
                    <option value="Villager">Villager</option>
                    <option value="Govt official">Govt official</option>
                    <option value="Tourist">Tourist</option>
                    <option value="Defence Person">Defence Person</option>
                    <option value="Ex-Defence Person">Ex-Defence Person</option>
                    <option value="OGW (Over Ground Worker)">OGW (Over Ground Worker)</option>
                    <option value="SM">SM</option>
                    <option value="Terrorist">Terrorist</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center w-1/4">
              <div>
                <div>
                  {selecetedCamera == 'web' ? (
                    <>
                      <Webcam
                        ref={webCamRef}
                        // audio={true}
                        screenshotFormat="image/png"
                        videoConstraints={videoConstraints}
                        mirrored={false}
                      />
                      <div className="flex mt-5 gap-4 justify-between">
                        <Button
                          onClick={captureFace}
                          type="button"
                          variant="contained"
                          className=" text-white mt-[1rem] rounded "
                          style={{ backgroundColor: '#22c55e', padding: '1em' }}
                          startIcon={
                            <PersonIcon
                              fontSize="large"
                              style={{
                                fontSize: '40px'
                              }}
                            />
                          }
                        >
                          <span>
                            Capture
                            <p className="font-bold">Person</p>
                          </span>
                        </Button>
                        <Button
                          onClick={captureDoc}
                          type="button"
                          variant="contained"
                          className=" text-white mt-[1rem] rounded "
                          style={{ backgroundColor: '#22c55e', padding: '1em' }}
                          startIcon={<DocumentScannerIcon style={{ fontSize: '40px' }} />}
                        >
                          <span>
                            Capture
                            <p className="font-bold">Document</p>
                          </span>
                        </Button>
                      </div>
                    </>
                  ) : null}
                  {selecetedCamera == 'ipCam' ? (
                    <div>
                      <Rtsp
                        capture={captureImageFromIp}
                        canvasClassName="h-[18rem]  w-[20rem] overflow-hidden"
                        backToWebCam={() => setSelectedCamera('web')}
                        disableFaceDetection={true}
                      />
                    </div>
                  ) : null}
                  {selecetedCamera == 'bodyCam' ? (
                    <div>
                      <Rtsp
                        capture={captureImageFromIp}
                        backToWebCam={() => setSelectedCamera('web')}
                        canvasClassName="h-[18rem]  w-[20rem] overflow-hidden"
                        disableFaceDetection={true}
                      />
                    </div>
                  ) : null}
                  <div className="mt-5 flex flex-col gap-4">
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      style={{ width: '', backgroundColor: '' }}
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput onChange={handleDocImageUpload} type="file" />
                      Upload Document To Add Details
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      style={{ width: '', backgroundColor: '' }}
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput onChange={handleFaceImageUpload} type="file" />
                      Upload Person Picture
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start mb-2 font-bold">
            <Checkbox
              checked={consentGiven}
              className="flex items-start"
              onChange={(e) => setConsentGiven(e.target.checked)}
            ></Checkbox>
            <span className="ml-1 text-left cursor-pointer" onClick={(e) => setConsentGiven(!consentGiven)}>
              {' '}
              I acknowledge that the individual has granted permission to collect their photographs, personal
              information, fingerprints, and documents.
            </span>
          </div>

          <div className="flex justify-between">
            {images.length > 0 ? (
              <div className="w-1/2 mr-2">
                <div className="mb-1 text-xl text-left">Persons Photo(s)</div>
                <div className="flex ">
                  {console.log(newUser, 'new user')}
                  {newUser?.imagebase64 ? (
                    <div className="relative mr-2 image-container">
                      <img
                        src={
                          newUser?.imagebase64?.includes('data')
                            ? newUser?.imagebase64
                            : 'data:image/png;base64,' + newUser?.imagebase64
                        }
                        className="w-[7rem] h-[7rem]"
                      />
                      <button
                        type="button"
                        onClick={() => setNewUser({ ...newUser, imagebase64: null })}
                        className="absolute left-0 w-full h-full py-1 bottom-1 backdrop-blur-sm image-delete-icon"
                      >
                        <Delete className="text-red-500 " />
                      </button>
                    </div>
                  ) : (
                    '(No Photos)'
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {newUser?.documents?.length > 0 ? (
              <div className="w-1/2 mr-2">
                <div className="mb-1 text-xl text-left">Persons Documents(s)</div>
                <div className="flex ">
                  {console.log(newUser, 'new  user')}
                  {newUser.documents.map((image, index) => (
                    <div className="relative mr-2 image-container" key={index}>
                      <ViewDocumentModel
                        index={index}
                        deleteDocument={() => deleteDocument(index)}
                        image={image?.includes('data') ? image : 'data:image/png;base64,' + image}
                        /* image={
                        images?.includes("data")
                          ? image
                          : "data:image/png;base64," + image
                      } */
                      />
                      {/* <div className="relative mr-2 image-container">
                        <img
                          src={"data:image/png;base64," + image}
                          className="w-[7rem] h-[7rem]"
                        />
                        <button
                          type="button"
                          onClick={() => deleteDocument(index)}
                          className="absolute left-0 w-full h-full py-1 bottom-1 backdrop-blur-sm image-delete-icon"
                        >
                          <Delete className="text-red-500 " />
                        </button>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Location Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-1/4 pr-3 mb-3 md:mb-3 custom-ant-select ">
                      <label
                        className="block mt-2 uppercase text-left tracking-wide  text-[1rem] "
                        htmlFor="grid-state"
                      >
                        Select Village
                      </label>
                      <Select
                        id="village"
                        showSearch
                        size="large"
                        optionFilterProp="children"
                        filterOption={filterVillageOption}
                        options={[
                          {
                            value: '',
                            label: 'Please select village'
                          },
                          ...villages
                        ]}
                        value={newUser?.village_id ? newUser.village_id : ''}
                        style={{
                          width: '100%',
                          height: '45px',
                          border: '1px solid #666666',
                          borderRadius: '0.25rem'
                        }}
                        onChange={handleVillageChange}
                        className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-left"
                      />
                    </div>
                    <div className="w-full pr-3 mb-3 md:w-1/2 md:mb-3">
                      <label
                        className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                        htmlFor="grid-first-name"
                      >
                        Address
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        id="grid-first-name"
                        type="text"
                        placeholder="Please enter address."
                        // disabled={location.state?.user}
                        onChange={(e) => setNewUser({ ...newUser, address: e.target.value })}
                        value={newUser.address}
                      />
                      {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                    </div>
                  </div>
                  {isOutsider ? (
                    <div className="flex flex-wrap w-2/3">
                      <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                          htmlFor="grid-first-name"
                        >
                          City
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          id="grid-first-name"
                          type="text"
                          placeholder="Please enter city."
                          // disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                          value={location.state?.user?.city ? location.state?.user?.city : newUser.city}
                        />
                      </div>
                      <div className="w-full pr-3 md:w-1/3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                          htmlFor="grid-last-name"
                        >
                          State
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Please enter state."
                          // disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, state: e.target.value })}
                          value={location.state?.user?.state ? location.state?.user.state : newUser.state}
                        />
                      </div>
                      <div className="w-full pr-3 md:w-1/3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] font-thin "
                          htmlFor="grid-last-name"
                        >
                          Zip
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Please enter zip."
                          // disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, pincode: e.target.value })}
                          value={location.state?.user?.pincode ? location.state?.user.pincode : newUser.pincode}
                        />
                      </div>
                      <div className="w-full pr-3 md:w-1/3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                          htmlFor="grid-last-name"
                        >
                          Country
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Please enter country."
                          // disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, country: e.target.value })}
                          value={location.state?.user?.country ? location.state?.user.country : newUser.country}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Social Media Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex flex-wrap my-3 ">
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Email ID
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Email ID."
                      value={newUser?.mail_id}
                      // disabled={location.state?.user}
                      onChange={(e) => setNewUser({ ...newUser, mail_id: e.target.value })}
                    />
                  </div>

                  <div className="w-full pr-3 md:w-2/3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-last-name"
                    >
                      Social Media ID
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="Please Enter Social ."
                      value={newUser?.social_media}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          social_media: e.target.value
                        })
                      }
                      // disabled={location.state?.user}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Educational Qualification</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex flex-wrap my-3 ">
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Qualification
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Qualification"
                      value={newUser?.qualification}
                      // disabled={location.state?.user}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          qualification: e.target.value
                        })
                      }
                    />
                  </div>

                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      School Or University
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter School."
                      value={newUser?.school}
                      // disabled={location.state?.user}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          school: e.target.value
                        })
                      }
                    />
                  </div>
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Education Info
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Education Info."
                      value={newUser?.education_info}
                      // disabled={location.state?.user}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          education_info: e.target.value
                        })
                      }
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Professional Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex flex-wrap my-3 ">
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Profession
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Profession."
                      value={newUser?.profession}
                      // disabled={location.state?.user}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          profession: e.target.value
                        })
                      }
                    />
                  </div>

                  <div className="w-full pr-3 md:w-2/3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-last-name"
                    >
                      Profession Description
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="Please Enter Professin Description."
                      value={newUser?.profession_remarks}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          profession_remarks: e.target.value
                        })
                      } // disabled={location.state?.user}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Relationship Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex justify-between mb-2 text-left ">
                  <span className="text-lg font-bold">Dependants</span>{' '}
                </div>
                <div className="flex flex-col items-start w-full pr-3 mb-3 person-category-container md:mb-3">
                  {dependants.map((dep, i) => (
                    <div className="pt-2 border-t-2 border-dotted" key={i}>
                      <DependantFields
                        i={i}
                        values={dep}
                        resident={location?.state?.user}
                        dependants={dependants}
                        setDependants={setDependants}
                        addDepFunc={(newDep) => {
                          const deps = [...dependants];
                          deps[i] = newDep;
                          setDependants(deps);
                        }}
                        removeDepFunc={() => {
                          const deps = [...dependants];
                          deps.splice(i, 1);
                          setDependants(deps);
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="flex justify-end px-[1rem]">
                  <button type="button" className="px-2 py-1 border-2 rounded" onClick={() => addNewDependant()}>
                    Add Another
                    <Add />
                  </button>
                </div>
                <div className="flex justify-between mb-2 text-left ">
                  <span className="text-lg font-bold">Non Dependants</span>{' '}
                </div>
                <div className="flex flex-col items-start w-full pr-3 mb-3 person-category-container md:mb-3">
                  {nonDependants.map((dep, i) => (
                    <div className="pt-2 border-t-2 border-dotted" key={i}>
                      {console.log(nonDependants, 'nonDependants')}
                      <DependantFields
                        i={i}
                        type="no-depend"
                        values={dep}
                        resident={location?.state?.user}
                        dependants={nonDependants}
                        setDependants={setNonDependants}
                        addDepFunc={(newDep) => {
                          const deps = [...nonDependants];
                          deps[i] = newDep;
                          setNonDependants(deps);
                        }}
                        removeDepFunc={() => {
                          const deps = [...nonDependants];
                          deps.splice(i, 1);
                          setNonDependants(deps);
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="flex justify-end px-[1rem]">
                  <button type="button" className="px-2 py-1 border-2 rounded" onClick={() => addNewNonDependant()}>
                    Add Another
                    <Add />
                  </button>
                  {/* {testing addRelatives api call} */}
                  {/* <button type="button" onClick={(e) => addRelatives("OT4132")}>
                    Create
                  </button> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">
                  Entry/Exit Remarks
                </span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div
                  className="grid grid-cols-2 gap-4"
                  style={{ width: "81%" }}
                >
                  <div className="pr-3 mb-3">
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="remarks1"
                    >
                      Remarks 1
                    </label>
                    <textarea
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="remarks1"
                      onChange={(e) => setRemarks1(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks1
                          ? location.state?.user?.village?.remarks1
                          : newUser.remarks1
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>

                  <div className="pr-3 mb-3">
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="remarks2"
                    >
                      Remarks 2
                    </label>
                    <textarea
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="remarks2"
                      onChange={(e) => setRemarks2(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks2
                          ? location.state?.user?.village?.remarks2
                          : newUser.remarks2
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>

                  <div className="pr-3 mb-3">
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="remarks3"
                    >
                      Remarks 3
                    </label>
                    <textarea
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="remarks3"
                      onChange={(e) => setRemarks3(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks3
                          ? location.state?.user?.village?.remarks3
                          : newUser.remarks3
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>

                  <div className="pr-3 mb-3">
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="remarks4"
                    >
                      Remarks 4
                    </label>
                    <textarea
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      id="remarks4"
                      onChange={(e) => setRemarks4(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks4
                          ? location.state?.user?.village?.remarks4
                          : newUser.remarks4
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div> */}

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Fingerprint Update</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex items-end justify-between ">
                  <EditFingerPrint
                    setRightThumb={setRightThumb}
                    rightThumb={rightThumb}
                    registeredThumb={registeredThumb}
                    setRegisteredThumb={setRegisteredThumb}
                  />
                  {console.log(registeredThumb, 'ddddd')}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="flex w-fit ml-auto items-center justify-center">
            <InputLabel
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bolder',
                color: 'black'
              }}
            >
              Verify Face
            </InputLabel>
            <Switch checked={verifyFace} onChange={(event) => setVerifyFace(event.target.checked)} />
          </div>

          <div className="flex flex-col items-end">
            <div>
              <Link to="/people">
                <button className="px-[4rem] py-3 text-white rounded mr-3 bg-gray-500">BACK</button>
              </Link>

              <button type="submit" className="px-[4rem] py-3 rounded  bg-green-500 text-white m-4">
                SUBMIT
              </button>
            </div>
          </div>
          <div className="flex justify-end mb-5"></div>
        </form>
      </div>
    </Spin>
  );
};

export default EditResidentDetails;
