import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { DatePicker, Select, Spin } from 'antd';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import ViewImageModal from '../Modals/ViewImageModal';
import { BACKEND_URL } from '../constants';

const ManualSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState('5');
  const [totalRecords, setTotalRecords] = useState();
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(false);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (!!searchTerm) {
      const delaySearch = setTimeout(() => {
        getPeopleData();
      }, 500);

      return () => clearTimeout(delaySearch);
    }
  }, [searchTerm]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'f_name', //access nested data with dot notation
        header: 'First Name',
        size: 150
      },
      {
        accessorKey: 'l_name',
        header: 'Last Name',
        size: 150
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 150
      },
      {
        accessorKey: 'village.v_name', //normal accessorKey
        header: 'Village',
        size: 200
      },
      {
        accessorKey: 'entry_or_exit_at',
        header: 'Last Recorded Entry / Exit',
        size: 150,

        accessorFn: (row) => {
          var gmtDateTime = moment.utc(row.entry_or_exit_at);
          var local = gmtDateTime.local().format('DD-MM-YYYY h:mm:ss A');
          return local;
        }
      },
      {
        accessorKey: 'age',
        header: 'Age',
        size: 10
      },
      {
        accessorKey: 'dob',
        header: 'DOB',
        size: 150,
        accessorFn: (row) => {
          var gmtDateTime = moment.utc(row.entry_or_exit_at);
          var local = gmtDateTime.local().format('DD-MM-YYYY');
          return local;
        }
      },

      {
        accessorKey: 'imagebase64',
        header: 'Image',
        size: 150,
        accessorFn: (row) => {
          return (
            <ViewImageModal
              image={row.imagebase64}
              metaData={{
                ...row,
                userInfo: { f_name: row.f_name, l_name: row.l_name }
              }}
            />
          );
        }
      },

      {
        // accessorKey: "imagebase64",
        header: 'Action',
        size: 150,
        accessorFn: (row) => {
          return (
            <Link to={'/new'} state={{ user: row }} className=" bg-blue-600 text-white px-2 py-1 rounded">
              View
            </Link>
          );
        }
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    initialState: { density: 'compact' },
    enableDensityToggle: false,
    data: residents,
    enableFilters: false,
    enablePagination: false,
    enableBottomToolbar: false
  });

  const getPeopleData = () => {
    setLoading(true);

    fetch(`${BACKEND_URL}/getUserInfo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          f_name: searchTerm.trim()
          //   ...filters,
          //   village_ids,
        },
        limit: parseInt(rowsPerPage),
        page: currentPage
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setResidents(data.result);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleNextPage = () => {
    if (currentPage == Math.ceil(totalRecords / rowsPerPage)) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage == 1) {
      return;
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Spin className="mt-[9rem]" size="large" spinning={loading}>
      <div className="mt-6">
        <div>
          <div style={{ fontWeight: '100' }} className="text-left text-[1.2rem] text-gray-500">
            Search Resident
          </div>
          <input
            placeholder="Enter name here"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-resident w-full border-2 rounded px-2 text-gray-600 h-[45px] text-lg   focus:outline-none"
          />
        </div>

        {/* {loading ? null : ( */}
        <>
          {residents.length > 0 ? (
            <div className="mt-3">
              <MaterialReactTable table={table} />
              <div className="mt-3 mb-5 flex justify-between">
                <div>
                  <span>Rows Per Page:</span>
                  <Select
                    className="mx-2"
                    options={[
                      {
                        value: '5',
                        label: '5'
                      },
                      {
                        value: '10',
                        label: '10'
                      },
                      {
                        value: '20',
                        label: '20'
                      }
                    ]}
                    onChange={(e) => {
                      setRowsPerPage(e);
                      setCurrentPage(1);
                    }}
                    value={rowsPerPage}
                  />
                </div>
                <div className="flex items-center ">
                  <ArrowBackIosIcon onClick={handlePrevPage} className="w-4" />
                  <div>
                    {currentPage}/{Math.ceil(totalRecords / parseInt(rowsPerPage))}
                  </div>
                  <ArrowForwardIosIcon onClick={handleNextPage} className="ml-2" />
                </div>
              </div>
            </div>
          ) : !!searchTerm && !loading ? (
            <div className="mt-10 text-2xl">No Data Found</div>
          ) : null}
        </>
        {/* )} */}
      </div>
    </Spin>
  );
};

export default ManualSearch;
