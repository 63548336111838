import { useState } from 'react';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { CaptureFinger } from '../utils/biometric';
import { notification } from 'antd';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

const AddFingerPrint = ({ rightThumb, setRightThumb }) => {
  const [thumbLoading, setThumbLoading] = useState(false);

  var quality = 60; //(1 to 100) (recommanded minimum 55)
  var timeout = 10;

  const captureFinger = async () => {
    setThumbLoading(true);
    setRightThumb(null);
    try {
      var res = await CaptureFinger(quality, timeout, setThumbLoading);
      if (res.httpStaus) {
        if (res.data.ErrorCode == '0') {
          if (res.data.Quality >= 60) {
            setRightThumb({
              iso: res.data.IsoTemplate,
              bitmap: res.data.BitmapData
            });
            notification.success({
              message: 'Fingerprint captured!!'
            });
          } else {
            notification.warning({
              message: 'Finger not detected properly!!'
            });
          }
        } else if (res.data.ErrorCode == '-1140') {
          notification.warning({
            message: 'No finger detected!!'
          });
        }
      } else {
        notification.warning({
          message: 'Something went wrong!!'
        });
      }
      setThumbLoading(false);
    } catch (e) {
      console.log(e);
      setThumbLoading(false);
    }
    return false;
  };

  return (
    <div className=" w-[28rem]  flex items-center">
      <div className="w-[7rem] flex flex-col grow-0  justify-start items-center  ">
        <div className="flex  w-[7rem] h-[9rem] border-2 items-center">
          {rightThumb?.bitmap ? (
            <img
              id="imgFinger"
              src={'data:image/bmp;base64,' + rightThumb?.bitmap}
              className={`w-[7rem] h-[9rem] object-fill `}
            />
          ) : null}
        </div>

        <div className="border-2 mt-2 w-[7rem] rounded ">
          {/* {rightThumb?.bitmap ? (
            <button
              onClick={() => setRightThumb(null)}
              type="button"
              className="px-2  h-7  rounded my-1 w-[7rem]"
            >
              <div className="text-red-400">
                <RotateLeftIcon />
                Reset
              </div>
            </button>
          ) : ( */}
          <button onClick={() => captureFinger()} type="button" className="px-2 h-7 rounded my-1 w-[7rem]">
            {thumbLoading ? <HourglassTopIcon /> : 'Add'}
          </button>
          {/* )} */}
        </div>
      </div>
      {rightThumb?.bitmap ? (
        <div className="mb-10 ml-3 font-bold">
          <FingerprintIcon className="text-green-600" /> Fingerprint captured successfully!
        </div>
      ) : null}
    </div>
  );
};

export default AddFingerPrint;
