import { Checkbox, DatePicker, Radio, Select, Spin, notification } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment-timezone';
import Webcam from 'react-webcam';
import Fingerprint from '../components/MatchFingerPrint';
import VillageContext from '../context/VillageContext';
import '../App.css';
import { Add, Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DependantFields from '../components/DependantFields';
import Rtsp from '../components/Rtsp';
import DocumentsUpload from '../components/DocumentsUpload';
import ViewDocumentModel from '../Modals/ViewDocumentModel';
import AddFingerPrint from '../components/AddFingerPrint';
import { BACKEND_URL, FRS_URL, currentFr, PYTHON_FRS_URL } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Switch from '@mui/material/Switch';

import axios from 'axios';
import { InputLabel } from '@mui/material';
const NewEntry = () => {
  const [newUser, setNewUser] = useState({});
  const [villages, setVillages] = useState([]);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isOutsider, setIsOutsider] = useState(false);
  const [txnType, setTxnType] = useState('Entry');
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [remarks1, setRemarks1] = useState('');
  const [remarks2, setRemarks2] = useState('');
  const [remarks3, setRemarks3] = useState('');
  const [remarks4, setRemarks4] = useState('');
  const [category, setCategory] = useState('');
  const [categoryRemarks, setCategoryRemarks] = useState('');
  const [purposeOfVisit, setPurposeOfVisit] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [vehicleMakeModel, setVehicleMakeModel] = useState('');
  const [vehicleRemarks, setVehicleRemarks] = useState('');
  const [dependants, setDependants] = useState([{}]);
  const [nonDependants, setNonDependants] = useState([{}]);
  const [profession, setProfession] = useState('');
  const [professionDescription, setProfessionDescription] = useState('');
  const [emailId, setEmailId] = useState('');
  const [socialMedia, setSocialMedia] = useState('');
  const [newSocialInputs, setNewSocialInputs] = useState([]);
  const [educationalInfo, setEducationalInfo] = useState('');
  const [qualification, setQualification] = useState('');
  const [school, setSchool] = useState('');
  const [selecetedCamera, setSelectedCamera] = useState('web');
  const [alias, setAlias] = useState('');
  const [religion, setReligion] = useState('');
  const [images, setImages] = useState([]);
  const [consentGiven, setConsentGiven] = useState(true);
  const [documentImages, setDocumentImages] = useState([]);
  const [cameraToggle, setCameraToggle] = useState('document');
  const [rightThumb, setRightThumb] = useState();

  const [registeredThumb, setRegisteredThumb] = useState();
  const [verifyFace, setVerifyFace] = useState(true);

  const { village, setVillage, node, setNode } = useContext(VillageContext);

  const webCamRef = useRef();

  const videoConstraints = {
    // width: 540,
    outerHeight: 1000,
    facingMode: 'environment'
  };

  const location = useLocation();
  const navigate = useNavigate();

  const txnId = uuidv4();

  const currentVillage = village.split('-')[0];

  const cameraOptions = [
    { label: <span className="text-[1rem]">Web Cam</span>, value: 'web' },
    { label: <span className="text-[1rem]">IP Camera</span>, value: 'ipCam' },
    {
      label: <span className="text-[1rem]">Body Camera</span>,
      value: 'bodyCam'
    }
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    colot: 'blue',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  });

  const handleDocImageUpload = (event) => {
    const file = event?.target?.files[0];
    ocrDetectOnUpload(event);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (documentImages?.length < 2) {
          setDocumentImages((prev) => [...prev, reader.result]);
        } else {
          notification.warning({ message: 'Only 2 Documents required' });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFaceImageUpload = (event) => {
    const file = event?.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages([reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const entryExitOptions = [
    {
      label: <span className={`text-[1.5rem] ${txnType == 'Entry' ? 'text-blue-600 font-bold' : ''}`}>Entry</span>,
      value: 'Entry'
    },
    {
      label: <span className={`text-[1.5rem] ${txnType == 'Exit' ? 'text-blue-600 font-bold' : ''}`}>Exit</span>,
      value: 'Exit'
    }
  ];

  useEffect(() => {
    getVillageList();
    if (location?.state?.image) {
      setImages([...images, location?.state?.image]);
    }

    if (location?.state?.user) {
      getRegisteredFingerPrint(location?.state?.user?.user_id);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.user) {
      const userId = location.state?.user?.user_id;

      const docs = location?.state?.user?.documents.map(
        (url) => url
        // (url) => "data:image/png;base64," + url
      );
      // setImages([...images, location?.state?.user?.image]);
      setDocumentImages(docs);
      getRelatives(userId);
    }
  }, []);

  const getAadharCardDetails = async (aadhar) => {
    const data = await fetch(`${BACKEND_URL}/validateAadharCard`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        aadhar
      })
    }).then(async (res) => await res.json());

    return await data;
  };

  const getVillageList = () => {
    fetch(`${BACKEND_URL}/getVillageDetails`)
      .then((res) => res.json())
      .then((data) => {
        const options = data.result.map((village, i) => {
          return {
            label: village.v_name,
            value: village.village_id
          };
        });
        setVillages(options);
      })
      .catch((err) => console.log(err));
  };

  const checkFace = async (base64Image) => {
    try {
      const response = await fetch(`${PYTHON_FRS_URL}/check_face`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          base64_image: base64Image
        })
      });

      if (!response.ok) {
        if (response.status === 400) {
          const data = await response.json();
          throw new Error(data.message);
        }
      }

      return await response.json();
    } catch (error) {
      throw new Error('Could Not Find Face');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const base64Image = images[0];

    try {
      const result = verifyFace ? await checkFace(base64Image) : { status: 'success' };

      if (result.status === 'success') {
        // Proceed with the form submission
        if (consentGiven) {
          if (!location.state?.user) {
            const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;

            if (images.length > 0 && images.length < numberOfImagesRequired) {
              notification.warning({
                message: `Please capture atleast ${numberOfImagesRequired} Photos. `
              });
              return;
            }

            if (
              !newUser.f_name ||
              !newUser.l_name
              // (images.length == 0 && !location.state?.image)
            ) {
              setError(true);
              return;
            } else {
              setError(false);
            }
          }
          setLoading(true);

          const data = {
            ...newUser,
            id: txnId,
            // village_id: newUser.village_id,
            // node_id: "N1027",
            // entry_or_exit_at: new Date().toISOString(),
            // entry_or_exit: "Entry",
            imagebase64: location.state?.image ? location.state?.image?.slice(22) : images[0]?.slice(22),
            religion: religion,
            alias_name: alias,
            profession: profession,
            profession_remarks: professionDescription,
            mail_id: emailId,
            social_media: socialMedia,
            education_info: educationalInfo,
            qualification: qualification,
            school: school,
            village_id: newUser?.village_id ? newUser?.village_id : currentVillage,
            villageEntryExits: {
              create: [
                {
                  imagebase64: location.state?.image ? location.state?.image?.slice(22) : images[0]?.slice(22),
                  // id: txnId,
                  village_id: currentVillage,
                  node_id: node.split('-')[0],
                  entry_or_exit_at: new Date().toISOString(),
                  entry_or_exit: txnType,
                  category_remarks: categoryRemarks,
                  category: category,
                  purpose_of_visit: purposeOfVisit,
                  vehicle_type: vehicleType,
                  vehicle_make_model: vehicleMakeModel,
                  vehicle_remarks: vehicleRemarks,
                  remarks1: remarks1,
                  remarks2: remarks2,
                  remarks3: remarks3,
                  remarks4: remarks4
                }
              ]
            }
          };

          console.log(txnId, 'txn');

          fetch(`${BACKEND_URL}/createNewUser`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.statusCode >= 200 && data.statusCode < 300) {
                notification.success({
                  message: 'User Created'
                });
                if (data?.result?.user_id) {
                  registerNewPersonV2(data?.result?.user_id);
                  addRelatives(data?.result?.user_id);
                  registerThumb(data?.result?.user_id);
                  uploadResidentDocuments(data?.result?.user_id, data?.result?.id);
                }
              } else {
                alert('Failed to create');
              }
              console.log(data);
              return data.statusCode;
            })
            .then((statusCode) => {
              if (statusCode == 201) {
                navigate('/');
              }
            })
            .catch((err) => {
              console.log(err);
              alert('Failed to create');
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          notification.warning({
            message: 'Please get consent from the concerned person!'
          });
        }
      }
    } catch (error) {
      // Display error message from checkFace
      toast.error(error.message);
    }
  };

  const getRegisteredFingerPrint = (uid) => {
    const query = {
      q: {
        user_id: uid
      }
    };
    fetch(`${BACKEND_URL}/getFingerprint`, {
      method: 'POST',
      body: JSON.stringify(query),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((data) => setRegisteredThumb(data.result[0]))
      .catch((err) => console.log(err));
  };

  const registerThumb = (uid) => {
    if (rightThumb?.bitmap) {
      fetch(`${BACKEND_URL}/createFingerprint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: uid, ...rightThumb })
      });
    }
  };

  const parseAadharXML = (xmlString) => {
    console.log('inside parse Aadhar');
    console.log(typeof xmlString);
    if (typeof xmlString !== 'object') {
      const cleanedXmlString = xmlString.replace(/(^"|"$)/g, '');
      // Log the cleaned XML string
      // console.log("Cleaned XML String:", cleanedXmlString);
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(cleanedXmlString, 'application/xml');
      // console.log("Parsed XML Document:", xmlDoc);
      const element = xmlDoc.getElementsByTagName('PrintLetterBarcodeData')[0];

      // console.log(element)
      if (element) {
        const aadhar = element.getAttribute('uid');
        const name = element.getAttribute('name');
        let gender = element.getAttribute('gender');
        const yob = element.getAttribute('yob');
        const co = element.getAttribute('co');
        const street = element.getAttribute('street');
        const im = element.getAttribute('lm');
        const loc = element.getAttribute('loc');
        const vtc = element.getAttribute('vtc');
        const po = element.getAttribute('po');
        const dist = element.getAttribute('dist');
        const subdist = element.getAttribute('subdist');
        const state = element.getAttribute('state');
        const pc = element.getAttribute('pc');
        const address = [im, loc, vtc, po, dist, subdist, state, pc].filter(Boolean).join(', ');
        let dob = element.getAttribute('dob');

        const nameParts = name.split(' ');
        const f_name = nameParts[0];
        const l_name = nameParts.slice(1).join(' ');
        const currentYear = new Date().getFullYear();
        const age = currentYear - parseInt(yob, 10);
        gender = gender === 'M' ? 'Male' : 'Female';
        dob = dayjs(dob, 'DD/MM/YYYY');
        setSelectedDate(dob);
        return {
          aadhar,
          f_name,
          l_name,
          gender,
          yob,
          co,
          street,
          vtc,
          po,
          dist,
          subdist,
          state,
          pc,
          dob,
          age,
          address
        };
      }
    } else {
      return {};
    }
    return {};
  };

  async function sendImageFile(imageUri) {
    try {
      // Fetch the image as a Blob
      const response = await axios.get(imageUri, { responseType: 'blob' });
      const blob = response.data;

      // Create a FormData object and append the file
      const formData = new FormData();
      formData.append('image', blob, 'image.png'); // 'image' is the key expected by the server, and 'image.png' is the filename

      // Send the POST request with the FormData
      const postResponse = await axios.post(`${PYTHON_FRS_URL}/aadhar`, formData);
      // Handle the response from the server
      console.log('Upload successful', postResponse.data.qr_data);
      const startTime = performance.now();
      console.log(postResponse.data);
      console.log(postResponse.data.qr_data);
      const aadharData = parseAadharXML(postResponse.data.qr_data);
      const endTime = performance.now();
      const timeTaken = endTime - startTime;
      console.log('Aadhar parsing time:', timeTaken, 'ms');
      console.log(aadharData);
      setNewUser(aadharData);
    } catch (error) {
      console.error('Error sending image:', error);
    }
  }

  const ocrDetectOnUpload = (e) => {
    console.log('inside ocrDetectOnUpload');
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log(file);
    if (file) {
      reader.onload = (event) => {
        const png_url = event.target.result;
        console.log(png_url); // The file data URL
        sendImageFile(png_url);
      };

      reader.readAsDataURL(file);
    } else {
      console.error('No file selected or invalid file type.');
    }
  };

  const handleOcrResult = (data) => {
    console.log('in handleOcr');
    console.log('OCR Data:', data);
    const aadharData2 = parseAadharXML(data.data);
    setNewUser(aadharData2);
    // Handle the OCR data (e.g., update state, display data, etc.)
  };

  const captureFace = (ipCam = false, faceImageUrl) => {
    console.log(ipCam);
    const src = ipCam && faceImageUrl ? faceImageUrl : webCamRef?.current?.getScreenshot();
    if (location?.state?.user) {
      setImages([src]);
    } else {
      const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;
      if (images?.length < numberOfImagesRequired) {
        setImages([...images, src]);
      } else {
        notification.warning({
          message: `Only ${numberOfImagesRequired} Photos required`
        });
      }
    }
  };

  const captureDoc = (ipCam = false, docImageUrl) => {
    const src = ipCam && docImageUrl ? docImageUrl : webCamRef?.current?.getScreenshot();
    sendImageFile(src);
    if (documentImages?.length < 2) {
      setDocumentImages([...documentImages, src]);
    } else {
      notification.warning({ message: 'Only 2 Documents required' });
    }
  };

  const captureImageFromIp = (docImgUrl, faceImgUrl) => {
    const docSrc = docImgUrl;
    const faceSrc = faceImgUrl;
    if (location?.state?.user) {
      setImages([faceSrc]);
    } else {
      if (cameraToggle == 'person') {
        const numberOfImagesRequired = currentFr == 'ayonix' ? 1 : 5;
        if (images?.length < numberOfImagesRequired) {
          setImages([...images, faceSrc]);
        } else {
          notification.warning({
            message: `Only ${numberOfImagesRequired} Photos required`
          });
        }
      } else {
        sendImageFile(docSrc);
        if (documentImages?.length < 2) {
          //dummy ocr value population
          if (newUser?.f_name?.toLowerCase().trim() == 'varun') {
            setLoading(true);
            notification.info({
              message: 'OCR Detecting...',
              duration: 3
            });
            setTimeout(() => {
              setNewUser({
                ...newUser,
                l_name: 'kumar s',
                aadhar: '12344 2323 2323'
              });
              notification.info({
                message: 'OCR Detected!',
                duration: 3
              });
              setLoading(false);
            }, 3000);
          }
          //dummy ocr value population
          setDocumentImages([...documentImages, docSrc]);
        } else {
          notification.warning({ message: 'Only 2 Documents required' });
        }
      }
    }
  };

  const createTransaction = (e) => {
    e.preventDefault();
    if (consentGiven) {
      // if (images.length == 0) {
      //   notification.error({
      //     title: "Image Missing!",
      //     message: "Please Capture Image",
      //   });
      //   return;
      // }
      const reqJson = {
        user_id: location.state?.user.user_id,
        village_id: currentVillage,
        node_id: node.split('-')[0],
        entry_or_exit_at: moment(new Date()).tz('Asia/Kolkata'),
        // imagebase64: location.state?.image?.slice(22),
        imagebase64: images[0]?.slice(22),

        entry_or_exit: txnType == 'Entry' ? 'Entry' : 'Exit',
        category_remarks: categoryRemarks,
        category: category,
        purpose_of_visit: purposeOfVisit,
        vehicle_type: vehicleType,
        vehicle_make_model: vehicleMakeModel,
        vehicle_remarks: vehicleRemarks,
        remarks1: remarks1,
        remarks2: remarks2,
        remarks3: remarks3,
        remarks4: remarks4
      };

      setLoading(true);
      fetch(`${BACKEND_URL}/createTransaction`, {
        method: 'POST',
        body: JSON.stringify(reqJson),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode == '201') {
            notification.success({
              message: `${txnType} marked`
            });
            setLoading(false);
            navigate('/');
          }
        })
        .catch((err) => console.log(err));
    } else {
      notification.warning({
        message: 'Please get consent from the concerned person!'
      });
    }
  };

  const deleteImage = (index) => {
    const imgs = [...images];
    imgs.splice(index, 1);
    setImages(imgs);
  };

  const deleteDocument = (index) => {
    if (location?.state?.user) {
      notification.warning({
        message: 'You are not authorized to delete this document!!'
      });
    } else {
      const imgs = [...documentImages];
      console.log(index, 'index');
      imgs.splice(index, 1);
      setDocumentImages(imgs);
    }
  };

  const registerNewPerson = (id) => {
    fetch(`${FRS_URL}/create_face_encodings`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: newUser.f_name,
        images: images.map((elem) => elem?.slice(22)),
        id
      })
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const registerNewPersonV2 = (id) => {
    fetch(`${PYTHON_FRS_URL}/store_face`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: newUser.f_name,
        base64_image: images[0],
        user_id: id
      })
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            toast.error('No face found in the image.');
          }
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Internal server error.');
      });
  };

  const addRelatives = (userId) => {
    const reqJson = {
      user_id: userId,
      relationship_json: {
        dependants,
        Nondependants: nonDependants
      }
    };

    console.log(reqJson, 'rel');

    fetch(`${BACKEND_URL}/createRelationship`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqJson)
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const addNewDependant = () => {
    setDependants([...dependants, {}]);
  };

  const addNewNonDependant = () => {
    setNonDependants([...nonDependants, {}]);
  };

  const getRelatives = (uid) => {
    setLoading(true);
    fetch(`${BACKEND_URL}/getRelationship`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        q: {
          user_id: uid
        },
        limit: 1000,
        page: 1
      })
    })
      .then(async (res) => {
        const data = await res.json();
        return data.result[0] ? data.result[0] : null;
      })
      .then((relatives) => {
        if (relatives?.relationship_json?.dependants) {
          setDependants(relatives?.relationship_json?.dependants);
        }

        if (relatives?.relationship_json?.Nondependants) {
          setNonDependants(relatives?.relationship_json?.Nondependants);
        }
      })
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const base64ToBlob = async (base64, fileName) => {
    const binaryString = window.atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return await blobToImageFile(await new Blob([arrayBuffer], { type: 'image/png' }), fileName);
  };

  async function blobToImageFile(blob, fileName) {
    return await new File([blob], fileName, { type: 'image/png' });
  }

  const urlToBlob = async (imageUrl, name) => {
    try {
      const response = await fetch(imageUrl);

      const blob = await response.blob();
      const namedBlob = await new Blob([blob], {
        type: 'image/png'
      });

      console.log(await blobToImageFile(namedBlob, name));

      return await blobToImageFile(namedBlob, name);
    } catch (error) {
      console.error('Error converting image URL to blob:', error);
      throw error;
    }
  };

  const uploadResidentDocuments = async (userId, id) => {
    if (!documentImages[0]) return;
    const formData = new FormData();

    for (let i = 0; i < documentImages.length; i++) {
      if (documentImages[i].includes('base64')) {
        formData.append(`files`, await base64ToBlob(documentImages[i], `${userId}_${id}_document_${i}.png`));
      } else {
        formData.append(`files`, await urlToBlob(documentImages[i], `${userId}_${id}_document_${i}.png`));
      }
    }

    formData.append('user_id', userId);
    formData.append('id', id);
    formData.append('purpose', 'userDocuments');

    fetch(`${BACKEND_URL}/fileupload`, {
      method: 'POST',
      body: formData
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const filterVillageOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleVillageChange = (value) => {
    setNewUser({
      ...newUser,
      village_id: value
    });
    setIsOutsider(value == 'V5042');
  };
  const addNewSocial = () => {
    setNewSocialInputs([...newSocialInputs, '']);
  };
  const handleSocialInputChange = (e, index) => {
    const updatedInputs = [...newSocialInputs];
    updatedInputs[index] = e.target.value;
    setNewSocialInputs(updatedInputs);
  };
  const handleSocialSave = () => {
    const appendedSocialMedia = socialMedia + ', ' + newSocialInputs.join(', ');
    console.log(appendedSocialMedia);
    setSocialMedia(appendedSocialMedia);
    setNewSocialInputs([]);
  };
  return (
    <Spin className="mt-[9rem]" size="large" spinning={loading}>
      {console.log(newUser, 'new user')}
      <div className="px-6 mt-2 bg-gray-50">
        <div className="flex justify-between py-4 my-3 border-b-2 ">
          <span className="text-3xl font-bold text-left text-blue-500">New Entry / Exit</span>
        </div>
        <form className="w-full max-w-full" onSubmit={location.state?.user ? createTransaction : handleSubmit}>
          <div className="flex justify-between">
            <p className="text-xl font-bold text-left">Personal Details</p>
            <Radio.Group
              options={cameraOptions}
              onChange={(e) => setSelectedCamera(e.target.value)}
              value={selecetedCamera}
            />
          </div>
          <div className="flex flex-wrap my-3 ">
            <div className="flex flex-wrap w-3/4">
              <div className="w-full pr-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  First Name *
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Please enter First Name."
                  value={location.state?.user?.f_name ? location.state?.user?.f_name : newUser.f_name}
                  disabled={location.state?.user}
                  onChange={(e) => setNewUser({ ...newUser, f_name: e.target.value })}
                />
                {error && !newUser?.f_name ? (
                  <p className="mt-1 text-xs italic text-left text-red-500">Please fill out this field.</p>
                ) : null}
              </div>

              <div className="w-full pr-3 md:w-1/3">
                <label className="block text-left mt-2  uppercase tracking-wide  text-[1rem] " htmlFor="grid-last-name">
                  Last Name *
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Please Enter Last Name."
                  value={location.state?.user?.l_name ? location.state?.user?.l_name : newUser.l_name}
                  onChange={(e) => setNewUser({ ...newUser, l_name: e.target.value })}
                  disabled={location.state?.user}
                />
                {error && !newUser?.l_name ? (
                  <p className="mt-1 text-xs italic text-left text-red-500">Please fill out this field.</p>
                ) : null}
              </div>
              <div className="w-full pr-3 md:w-1/3">
                <label className="block text-left mt-2  uppercase tracking-wide  text-[1rem] " htmlFor="grid-last-name">
                  Alias
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Please Enter Alias."
                  value={location?.state?.user?.alias_name ? location?.state?.user?.alias_name : alias}
                  onChange={(e) => setAlias(e.target.value)}
                  disabled={location.state?.user}
                />
              </div>
              <div className="w-full py-3 pr-3 md:w-1/3 md:mb-0 ">
                <label className="block uppercase text-left tracking-wide    text-[1rem] " htmlFor="grid-state">
                  Gender
                </label>
                <div className="relative ">
                  <select
                    className={`block appearance-none w-full bg-white  border border-gray-200  py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${
                      newUser?.gender == 'Please select gender.' || !newUser.gender ? 'text-gray-400' : ''
                    }`}
                    id="grid-state"
                    onChange={(e) => setNewUser({ ...newUser, gender: e.target.value })}
                    value={location.state?.user?.gender ? location.state?.user?.gender : newUser.gender}
                    disabled={location.state?.user}
                  >
                    <option className="text-gray-400">Please select your gender.</option>
                    <option className="text-gray-800">Male</option>
                    <option className="text-gray-800">Female</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Contact
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  onChange={(e) => setNewUser({ ...newUser, contact: e.target.value })}
                  value={location.state?.user?.contact ? location.state?.user?.contact : newUser.contact}
                  placeholder="Please enter contact number."
                  disabled={location.state?.user}
                />
              </div>
              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Age
                </label>
                <input
                  className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="number"
                  placeholder="Please enter  age."
                  onChange={(e) => {
                    setNewUser({ ...newUser, age: e.target.value });
                  }}
                  value={location.state?.user?.age ? location.state?.user?.age : newUser.age}
                  disabled={location.state?.user}
                />
              </div>
              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Date of Birth
                </label>
                <DatePicker
                  disabled={location.state?.user}
                  defaultValue={location.state?.user?.dob ? dayjs(location.state?.user?.dob) : dayjs(newUser.dob)}
                  format={'MM-DD-YYYY'}
                  onChange={(e) => {
                    if (e) {
                      setNewUser({
                        ...newUser,
                        dob: new Date(e.toDate()).toISOString()
                      });
                    }
                  }}
                  // allowClear={false}
                  className="w-full h-[45px]"
                />
              </div>

              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  Religion
                </label>
                <input
                  disabled={location.state?.user}
                  className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Please enter Religion."
                  onChange={(e) => setReligion(e.target.value)}
                  value={location?.state?.user?.religion ? location?.state?.user?.religion : religion}
                />
              </div>

              <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                <label
                  className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                  htmlFor="grid-first-name"
                >
                  ID Proof / Aadhaar
                </label>
                <input
                  disabled={location.state?.user}
                  className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Please enter aadhaar number."
                  onBlur={async () => {
                    const user = await getAadharCardDetails(
                      location.state?.user?.aadhar ? location.state?.user?.aadhar : newUser.aadhar
                    );

                    if (user.user_id) {
                      notification?.warning({
                        message: `Aadhaar number is already registered by ${user.f_name} ${user.l_name}`
                      });
                      setNewUser({ ...newUser, aadhar: undefined });
                    }
                  }}
                  onChange={(e) => {
                    setNewUser({ ...newUser, aadhar: e.target.value });
                  }}
                  value={
                    location.state?.user?.aadhar ? location.state?.user?.aadhar : newUser.aadhar ? newUser?.aadhar : ''
                  }
                />
              </div>
            </div>

            <div className="flex justify-center w-1/4">
              <div>
                <div>
                  {selecetedCamera == 'web' ? (
                    <>
                      <Webcam
                        ref={webCamRef}
                        // audio={true}
                        screenshotFormat="image/png"
                        videoConstraints={videoConstraints}
                        mirrored={false}
                      />
                      <div className="flex mt-5 gap-4 justify-between">
                        <Button
                          onClick={captureFace}
                          type="button"
                          variant="contained"
                          className=" text-white mt-[1rem] rounded "
                          style={{ backgroundColor: '#22c55e', padding: '1em' }}
                          startIcon={
                            <PersonIcon
                              fontSize="large"
                              style={{
                                fontSize: '40px'
                              }}
                            />
                          }
                        >
                          <span>
                            Capture
                            <p className="font-bold">Person</p>
                          </span>
                        </Button>
                        <Button
                          onClick={captureDoc}
                          type="button"
                          variant="contained"
                          className=" text-white mt-[1rem] rounded "
                          style={{ backgroundColor: '#22c55e', padding: '1em' }}
                          startIcon={<DocumentScannerIcon style={{ fontSize: '40px' }} />}
                        >
                          <span>
                            Capture
                            <p className="font-bold">Document</p>
                          </span>
                        </Button>
                      </div>
                    </>
                  ) : null}
                  {selecetedCamera == 'ipCam' ? (
                    <div>
                      <Rtsp
                        capture={captureImageFromIp}
                        showCaptureButton={true}
                        captureDoc={captureDoc}
                        captureFace={captureFace}
                        canvasClassName="h-[18rem]  w-[20rem] overflow-hidden"
                        backToWebCam={() => setSelectedCamera('web')}
                      />
                    </div>
                  ) : null}
                  {selecetedCamera == 'bodyCam' ? (
                    <div>
                      <Rtsp
                        capture={captureImageFromIp}
                        showCaptureButton={true}
                        captureDoc={captureDoc}
                        captureFace={captureFace}
                        backToWebCam={() => setSelectedCamera('web')}
                        canvasClassName="h-[18rem]  w-[20rem] overflow-hidden"
                      />
                    </div>
                  ) : null}
                  <div className="mt-5 flex flex-col  gap-4">
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      style={{ width: '', backgroundColor: 'white' }}
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput onChange={handleDocImageUpload} type="file" />
                      Upload Document To Add Details
                    </Button>
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      style={{ width: '', backgroundColor: 'white' }}
                      startIcon={<CloudUploadIcon />}
                    >
                      <VisuallyHiddenInput onChange={handleFaceImageUpload} type="file" />
                      Upload Person Picture
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start mb-2 font-bold">
            <Checkbox
              checked={consentGiven}
              className="flex items-start"
              onChange={(e) => setConsentGiven(e.target.checked)}
            ></Checkbox>
            <span className="ml-1 text-left cursor-pointer" onClick={(e) => setConsentGiven(!consentGiven)}>
              {' '}
              I acknowledge that the individual has granted permission to collect their photographs, personal
              information, fingerprints, and documents.
            </span>
          </div>

          <div className="flex justify-between">
            {images.length > 0 ? (
              <div className="w-1/2 mr-2">
                <div className="mb-1 text-xl text-left">Persons Photo(s)</div>
                <div className="flex ">
                  {images.map((image, index) => (
                    <div className="relative mr-2 image-container" key={index}>
                      <img src={image} className="w-[7rem] h-[7rem]" />
                      <button
                        type="button"
                        onClick={() => deleteImage(index)}
                        className="absolute left-0 w-full h-full py-1 bottom-1 backdrop-blur-sm image-delete-icon"
                      >
                        <Delete className="text-red-500 " />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {documentImages[0] ? (
              <div className="w-1/2 mr-2">
                <div className="mb-1 text-xl text-left">Persons Documents(s)</div>
                <div className="flex ">
                  {documentImages.map((image, index) => (
                    <div className="relative mr-2 image-container" key={index}>
                      <ViewDocumentModel
                        image={image.includes('data') ? image : 'data:image/bmp;base64,' + image}
                        index={index}
                        deleteDocument={(index) => {
                          deleteDocument(index);
                          // notification.warning({
                          //   message:
                          //     "You are not authorized to delete this document!!",
                          // });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                {console.log(currentVillage, 'vollage')}

                <span className="accordion-summary-text">Location Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div>
                  <div className="flex flex-wrap ">
                    <div className="w-1/4 pr-3 mb-3 md:mb-3">
                      <label
                        className="block mt-2 uppercase text-left tracking-wide  text-[1rem] "
                        htmlFor="grid-state"
                      >
                        Select Village
                      </label>
                      <Select
                        id="village"
                        showSearch
                        size="large"
                        optionFilterProp="children"
                        filterOption={filterVillageOption}
                        options={[
                          {
                            value: '',
                            label: 'Please select village'
                          },
                          ...villages
                        ]}
                        value={newUser?.village_id ? newUser.village_id : currentVillage.split(',')[0]}
                        style={{
                          width: '100%',
                          height: '45px',
                          border: '1px solid #e5e7eb',
                          borderRadius: '0.25rem'
                        }}
                        onChange={handleVillageChange}
                        className="text-left"
                      />
                    </div>
                    <div className="w-full pr-3 mb-3 md:w-1/2 md:mb-3">
                      <label
                        className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                        htmlFor="grid-first-name"
                      >
                        Address
                      </label>
                      <input
                        className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                        placeholder="Please enter address."
                        disabled={location.state?.user}
                        onChange={(e) => setNewUser({ ...newUser, address: e.target.value })}
                        value={location.state?.user?.address ? location.state?.user.address : newUser.address}
                      />
                      {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
                    </div>
                  </div>
                  {isOutsider ? (
                    <div className="flex flex-wrap w-2/3">
                      <div className="w-full pr-3 mb-3 md:w-1/3 md:mb-3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                          htmlFor="grid-first-name"
                        >
                          City
                        </label>
                        <input
                          className="block w-full px-4 py-3 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                          placeholder="Please enter city."
                          disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                          value={location.state?.user?.city ? location.state?.user?.city : newUser.city}
                        />
                      </div>
                      <div className="w-full pr-3 md:w-1/3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                          htmlFor="grid-last-name"
                        >
                          State
                        </label>
                        <input
                          className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Please enter state."
                          disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, state: e.target.value })}
                          value={location.state?.user?.state ? location.state?.user.state : newUser.state}
                        />
                      </div>
                      <div className="w-full pr-3 md:w-1/3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] font-thin "
                          htmlFor="grid-last-name"
                        >
                          Zip
                        </label>
                        <input
                          className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Please enter zip."
                          disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, pincode: e.target.value })}
                          value={location.state?.user?.pincode ? location.state?.user.pincode : newUser.pincode}
                        />
                      </div>
                      <div className="w-full pr-3 md:w-1/3">
                        <label
                          className="block text-left mt-2  uppercase tracking-wide text-[1rem] "
                          htmlFor="grid-last-name"
                        >
                          Country
                        </label>
                        <input
                          className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Please enter country."
                          disabled={location.state?.user}
                          onChange={(e) => setNewUser({ ...newUser, country: e.target.value })}
                          value={location.state?.user?.country ? location.state?.user.country : newUser.country}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Social Media Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex flex-wrap my-3 ">
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Email ID
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Email ID."
                      value={location?.state?.user?.mail_id ? location?.state?.user?.mail_id : emailId}
                      disabled={location.state?.user}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </div>

                  <div className="w-full pr-3 md:w-2/3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-last-name"
                    >
                      Social Media ID
                    </label>
                    <div className="flex w-full">
                      <input
                        className="block w-3/4 px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-last-name"
                        type="text"
                        placeholder="Please Enter Social Media ID."
                        value={location?.state?.user?.social_media ? location?.state?.user?.social_media : socialMedia}
                        onChange={(e) => setSocialMedia(e.target.value)}
                        disabled={location.state?.user}
                      />
                      <button type="button" className=" w-1/4 ml-2 border-2 rounded" onClick={addNewSocial}>
                        Add Another
                        <Add />
                      </button>
                    </div>
                    {newSocialInputs.map((input, index) => (
                      <div key={index} className="flex w-full mt-2">
                        <input
                          className="block w-3/4 px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder="Please Enter Additional Social Media ID."
                          value={input}
                          onChange={(e) => handleSocialInputChange(e, index)}
                        />
                      </div>
                    ))}
                    {newSocialInputs.length > 0 && (
                      <button type="button" className="mt-2 border-2 rounded px-4 py-2" onClick={handleSocialSave}>
                        Save All
                      </button>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Educational Qualification</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex flex-wrap my-3 ">
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Qualification
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Qualification."
                      value={
                        location?.state?.user?.qualification ? location?.state?.user?.qualification : qualification
                      }
                      disabled={location.state?.user}
                      onChange={(e) => setQualification(e.target.value)}
                    />
                  </div>

                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      School Or University
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter School."
                      value={location?.state?.user?.school ? location?.state?.user?.school : school}
                      disabled={location.state?.user}
                      onChange={(e) => setSchool(e.target.value)}
                    />
                  </div>
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Education Info
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Education."
                      value={
                        location?.state?.user?.education_info ? location?.state?.user?.education_info : educationalInfo
                      }
                      disabled={location.state?.user}
                      onChange={(e) => setEducationalInfo(e.target.value)}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Professional Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex flex-wrap my-3 ">
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-first-name"
                    >
                      Profession
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                      placeholder="Please enter Profession."
                      value={location?.state?.user?.profession ? location?.state?.user?.profession : profession}
                      disabled={location.state?.user}
                      onChange={(e) => setProfession(e.target.value)}
                    />
                  </div>

                  <div className="w-full pr-3 md:w-2/3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-last-name"
                    >
                      Profession Description
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight text-gray-700 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="Please Enter Profession Description."
                      value={
                        location?.state?.user?.profession_remarks
                          ? location?.state?.user?.profession_remarks
                          : professionDescription
                      }
                      onChange={(e) => setProfessionDescription(e.target.value)}
                      disabled={location.state?.user}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">Relationship Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="flex justify-between mb-2 text-left ">
                  <span className="text-lg font-bold">Dependants</span>{' '}
                </div>
                <div className="flex flex-col items-start w-full pr-3 mb-3 person-category-container md:mb-3">
                  {dependants.map((dep, i) => (
                    <div className="pt-2 border-t-2 border-dotted" key={i}>
                      <DependantFields
                        i={i}
                        values={dep}
                        dependants={dependants}
                        setDependants={setDependants}
                        resident={location?.state?.user}
                        addDepFunc={(newDep) => {
                          const deps = [...dependants];
                          deps[i] = newDep;
                          setDependants(deps);
                        }}
                        removeDepFunc={() => {
                          let deps = [...dependants];
                          deps.splice(i, 1);
                          setDependants(deps);
                        }}
                      />
                    </div>
                  ))}
                </div>
                {console.log(dependants, 'deps')}

                {location?.state?.user ? null : (
                  <div className="flex justify-end px-[1rem]">
                    <button type="button" className="px-2 py-1 border-2 rounded" onClick={() => addNewDependant()}>
                      Add Another
                      <Add />
                    </button>
                  </div>
                )}
                <div className="flex justify-between mb-2 text-left ">
                  <span className="text-lg font-bold">Non Dependants</span>{' '}
                </div>
                <div className="flex flex-col items-start w-full pr-3 mb-3 person-category-container md:mb-3">
                  {nonDependants.map((dep, i) => (
                    <div className="pt-2 border-t-2 border-dotted" key={i}>
                      <DependantFields
                        i={i}
                        type="no-depend"
                        values={dep}
                        resident={location?.state?.user}
                        dependants={nonDependants}
                        setDependants={setNonDependants}
                        addDepFunc={(newDep) => {
                          const deps = [...nonDependants];
                          deps[i] = newDep;
                          setNonDependants(deps);
                        }}
                        removeDepFunc={() => {
                          const deps = [...nonDependants];
                          deps.splice(i, 1);
                          setNonDependants(deps);
                        }}
                      />
                    </div>
                  ))}
                </div>
                {location?.state?.user ? null : (
                  <div className="flex justify-end px-[1rem]">
                    <button type="button" className="px-2 py-1 border-2 rounded" onClick={() => addNewNonDependant()}>
                      Add Another
                      <Add />
                    </button>
                    {/* {testing addRelatives api call} */}
                    {/* <button type="button" onClick={(e) => addRelatives("OT4132")}>
                    Create
                  </button> */}
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
              >
                <span className="accordion-summary-text">
                  Fingerprint {location?.state?.user ? 'Verification' : 'Registration'}
                </span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                {location?.state?.user ? (
                  <Fingerprint
                    type={location?.state?.user ? 'Verify' : null}
                    rightThumb={rightThumb}
                    setRightThumb={setRightThumb}
                    registeredThumb={registeredThumb}
                  />
                ) : (
                  <AddFingerPrint
                    type={location?.state?.user ? 'Verify' : null}
                    rightThumb={rightThumb}
                    setRightThumb={setRightThumb}
                    registeredThumb={registeredThumb}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full bg-blue-500">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
                style={{
                  backgroundColor: '#60a5fa'
                }}
              >
                <span className="accordion-summary-text">Remarks</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="grid grid-cols-2 gap-4" style={{ width: '81%' }}>
                  <div className="pr-3 mb-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks1">
                      Remarks 1
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="remarks1"
                      onChange={(e) => setRemarks1(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks1
                          ? location.state?.user?.village?.remarks1
                          : newUser.remarks1
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>

                  <div className="pr-3 mb-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks2">
                      Remarks 2
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="remarks2"
                      onChange={(e) => setRemarks2(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks2
                          ? location.state?.user?.village?.remarks2
                          : newUser.remarks2
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>

                  <div className="pr-3 mb-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks3">
                      Remarks 3
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="remarks3"
                      onChange={(e) => setRemarks3(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks3
                          ? location.state?.user?.village?.remarks3
                          : newUser.remarks3
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>

                  <div className="pr-3 mb-3">
                    <label className="block text-left mt-2 uppercase tracking-wide text-[1rem]" htmlFor="remarks4">
                      Remarks 4
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="remarks4"
                      onChange={(e) => setRemarks4(e.target.value)}
                      value={
                        location.state?.user?.village?.remarks4
                          ? location.state?.user?.village?.remarks4
                          : newUser.remarks4
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
                style={{
                  backgroundColor: '#60a5fa'
                }}
              >
                <span className="accordion-summary-text">Entry/Exit Vehicle Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div
                  className="flex vehicle-container"
                  // style={{ display: "contents" }}
                >
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Type
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-vehicle_type"
                      type="text"
                      placeholder="Please enter Vehicle type"
                      value={location.state?.user?.vehicle_type ? location.state?.user?.vehicle_type : vehicleType}
                      onChange={(e) => setVehicleType(e.target.value)}
                    />
                  </div>
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Number
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-vehicle_type"
                      type="text"
                      placeholder="Please enter Vehicle type"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                  </div>
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Make Model
                    </label>
                    <input
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-vehicle_type"
                      type="text"
                      placeholder="Please Enter Vehicle Make Model"
                      value={
                        location.state?.user?.vehicle_make_model
                          ? location.state?.user?.vehicle_make_model
                          : newUser.vehicle_make_model
                      }
                      onChange={(e) => setVehicleMakeModel(e.target.value)}
                    />
                  </div>
                  <div className="w-full pr-3 md:w-1/3 md:mb-3">
                    <label
                      className="block text-left mt-2  uppercase tracking-wide  text-[1rem] "
                      htmlFor="grid-vehicle_type"
                    >
                      Vehicle Remarks
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="grid-vehicle_remarks"
                      type="text"
                      placeholder="Please enter Vehicle Remarks"
                      style={{ width: '81%', height: '48%' }}
                      value={
                        location.state?.user?.vehicle_remarks
                          ? location.state?.user?.vehicle_remarks
                          : newUser.vehicle_remarks
                      }
                      onChange={(e) => setVehicleRemarks(e.target.value)}
                    />
                    {/* {error && !newUser?.vehicle_remarks ? (
                        <p className="mt-1 text-xs italic text-left text-red-500">
                          Please fill out this field.
                        </p>
                      ) : null} */}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* category for person entry & exit ---end code */}

          {/* category for person entry & exit ---start code */}
          <div className="w-full">
            <Accordion className="accordion-container">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-summary"
                style={{
                  backgroundColor: '#60a5fa'
                }}
              >
                <span className="accordion-summary-text">Entry/Exit Visit Details</span>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <div className="w-full pr-3 mb-3 person-category-container md:mb-3" style={{ display: 'flex' }}>
                  <div className="w-1/3">
                    <label className="block mt-2 uppercase text-left tracking-wide text-[1rem]" htmlFor="grid-state">
                      Person Category
                    </label>
                    <div className="relative">
                      <select
                        className={`block appearance-none bg-white w-full mb-2 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white ${
                          newUser?.category === 'Select Category for persons entering or exiting' || !newUser.category
                            ? 'text-gray-400'
                            : ''
                        }`}
                        id="grid-state"
                        onChange={(e) => {
                          setCategory(e.target.value);
                          setIsOutsider(e.target.value === 'V5042');
                        }}
                        value={location.state?.user?.category ? location.state?.user?.category : newUser.category}
                      >
                        <option value={''}>Please select category of person</option>
                        <option value="Villager">Villager</option>
                        <option value="Govt official">Govt official</option>
                        <option value="Tourist">Tourist</option>
                        <option value="Defence Person">Defence Person</option>
                        <option value="Ex-Defence Person">Ex-Defence Person</option>
                        <option value="OGW (Over Ground Worker)">OGW (Over Ground Worker)</option>
                        <option value="SM">SM</option>
                        <option value="Terrorist">Terrorist</option>
                        <option value="Other">Other</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/3 ml-3">
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="category_remarks"
                    >
                      Person Remarks
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="category_remarks"
                      onChange={(e) => setCategoryRemarks(e.target.value)}
                      value={
                        location.state?.user?.category_remarks
                          ? location.state?.user?.category_remarks
                          : newUser.category_remarks
                      }
                      placeholder="Enter Remarks Here..."
                    />
                  </div>
                  <div className="w-1/3 ml-3" style={{ display: 'inline' }}>
                    <label
                      className="block text-left mt-2 uppercase tracking-wide text-[1rem]"
                      htmlFor="purpose_of_visit"
                    >
                      Visit Purpose
                    </label>
                    <textarea
                      className="block w-full px-4 py-3 leading-tight border rounded appearance-none focus:outline-none focus:bg-white"
                      id="purpose_of_visit"
                      style={{ width: '100%', height: '48%' }}
                      onChange={(e) => setPurposeOfVisit(e.target.value)}
                      value={
                        location.state?.user?.purpose_of_visit
                          ? location.state?.user?.purpose_of_visit
                          : newUser.purpose_of_visit
                      }
                      placeholder="Enter Visit Purpose Here..."
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="flex justify-end w-full mt-7 items-center">
            <Radio.Group
              size="large"
              className=""
              options={entryExitOptions}
              value={txnType}
              onChange={(e) => setTxnType(e.target.value)}
            />

            <InputLabel
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bolder',
                color: 'black'
              }}
            >
              Verify Face
            </InputLabel>
            <Switch checked={verifyFace} onChange={(event) => setVerifyFace(event.target.checked)} />
          </div>

          <div className="flex justify-end ">
            <div>
              <Link to="/">
                <button className="px-[4rem] py-3 text-white rounded mr-3 bg-gray-500">BACK</button>
              </Link>

              <button type="submit" className="px-[4rem] py-3 rounded  bg-green-500 text-white my-4">
                SUBMIT
              </button>
            </div>
          </div>

          <div className="flex justify-end mb-5"></div>
        </form>
      </div>
    </Spin>
  );
};

export default NewEntry;
