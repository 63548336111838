import { useState } from 'react';

const { Modal, notification } = require('antd');

const ViewDocumentModel = ({ image, metaData, deleteDocument, index }) => {
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClose = () => setOpen(false);

  const footer = (
    <div className="py-2 pb-0 flex justify-between ">
      <button
        className="border-2 border-red-600 text-red-600 px-2 py-1 rounded justify-self-end"
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        Delete Document
      </button>
      <button className="border-2 px-2 py-1  rounded justify-self-end" onClick={handleClose}>
        Close
      </button>
    </div>
  );

  return (
    <div>
      <Modal open={open} onCancel={handleClose} footer={footer} closeIcon={null}>
        <div className="flex justify-center">
          <img src={image} className="h-[20rem] w-[full] object-cover " />
        </div>
      </Modal>
      <Modal
        title="Are you sure?"
        open={showDeleteModal}
        closeIcon={null}
        footer={
          <div className="">
            <button onClick={() => setShowDeleteModal(false)} className="border-2 px-3 py-1 rounded mr-2">
              Cancel
            </button>
            <button
              onClick={() => {
                deleteDocument(index);
                setShowDeleteModal(false);
                setOpen(false);
              }}
              className=" px-3 py-1 rounded mr-2 bg-red-700 text-white"
            >
              Delete
            </button>
          </div>
        }
      >
        <p>Do you want to delete the document?</p>
      </Modal>
      <button type="button">
        <img
          className="w-[7rem] h-[7rem] object-cover"
          onClick={() => {
            if (image) setOpen(true);
          }}
          src={image}
        />
      </button>
    </div>
  );
};

export default ViewDocumentModel;
